<template>
    <div>
    <b-breadcrumb :items="[{
        text: 'Daftar Kategori Produk',
        href: '#/product-categories'
    }, {
        text: 'Detail',
        active: true
    }]"></b-breadcrumb>
    <b-card>
        <b-row>
            <b-col md="12">
                <b-row>
                    <b-col md="6">
                        <h3 class="page-title mb-0"> {{model.name}}</h3>
                    </b-col>
                    <b-col md="6" class="text-right">
                        <b-button :href="'#/product-categories/'+$route.params.id+'/edit'" size="md" variant="secondary"><font-awesome-icon icon="edit" /> Edit</b-button>
                    </b-col>
                </b-row>
            </b-col>
            <b-col md="6">
                <b-row v-if="$webConfig.product_category.parent.logo" class="mt-3">
                    <b-col md="3">
                        <strong>Logo</strong>
                    </b-col>
                    <b-col md="9">
                        <img :src="model.logo_url" class="img-fluid" alt="">
                    </b-col>
                </b-row>
                <b-row class="mt-3">
                    <b-col md="3">
                        <strong>Urutan</strong>
                    </b-col>
                    <b-col md="9">
                        {{model.sort}}
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-card>
    </div>
</template>
<script>
  import QuillEditor from '@/components/QuillEditor'
    export default {
        components: {
          QuillEditor
        },
        data () {
            return {
                isLoading: {
                    productCategory: true,
                    subCategory: true,
                    customField: true
                },
                apiUrl: {
                    productCategory: process.env.VUE_APP_API_URL + 'product-categories',
                    customField: process.env.VUE_APP_API_URL + 'custom-fields'
                },
                model: {
                    parent: null,
                    id: null,
                    name: null,
                    logo_url: null,
                    sort: null,
                    description: null,
                    subCategories: [],
                    customFields: []
                },
                modalModel: {
                    customField: {
                        id: null,
                        is_required: 1,
                        sort: null,
                        name: null,
                        type: 'number',
                        description: null
                    }
                },
                // modalModel: {
                //     : {
                //         id: null,
                //         name: null,
                //         logo: null,
                //         sort: null
                //     }
                // },
                tables: {
                    subCategories: {
                        fields: [
                            {
                                key: 'name',
                                label: 'Nama',
                                visible: true
                            },
                            // {
                            //     key: 'logo_url',
                            //     label: 'Icon',
                            //     visible: this.$webConfig.product_category.sub.logo
                            // },
                            {
                                key: 'sort',
                                label: 'Urutan',
                                visible: true
                            },
                            {
                                key: 'description',
                                label: 'Deskripsi',
                                visible: true
                            },
                            {
                                key: 'is_active',
                                label: 'Status',
                                visible: true,
                                toggleFunction: (index, key, value) => {
                                    if (this.apiUrl.subCategory === false) {
                                        this.model.subCategories[index][key] = !value
                                        this.apiUrl.subCategory = true
                                        return this.$http.patch(this.apiUrl.productCategory + '/' + this.model.subCategories[index].id,{
                                            is_active: this.model.subCategories[index][key] ? 1 : 0
                                        }).then(() => {
                                            this.apiUrl.subCategory = false
                                            this.$store.dispatch('notification/success', 'Status berhasil diubah.')
                                        }).catch(error => {
                                            this.apiUrl.subCategory = false
                                            this.model.subCategories[index][key] = !value
                                            this.$store.dispatch('notification/error', error)
                                        })
                                    }
                                }
                            },
                            {
                                key: 'actions',
                                label: '',
                                visible: true
                            }
                        ]
                    },
                    customFields: {
                        fields: [
                            {
                                key: 'name',
                                label: 'Nama'
                            },
                            {
                                key: 'is_required',
                                label: 'Wajib Diisi?',
                                toggleFunction: (index, key, value) => {
                                    if (this.isLoading.customField === false) {
                                        this.model.customFields[index][key] = !value
                                        this.isLoading.customField = true
                                        return this.$http.patch(this.apiUrl.customField + '/' + this.model.customFields[index].id,{
                                            is_required: this.model.customFields[index][key] ? 1 : 0
                                        }).then(() => {
                                            this.isLoading.customField = false
                                            this.$store.dispatch('notification/success', 'Wajib Diisi berhasil diubah.')
                                        }).catch(error => {
                                            this.isLoading.customField = false
                                            this.model.customFields[index][key] = !value
                                            this.$store.dispatch('notification/error', error)
                                        })
                                    }
                                }
                            },
                            {
                                key: 'sort',
                                label: 'Urutan',
                                sortable: true
                            },
                            {
                                key: 'is_active',
                                label: 'Status',
                                toggleFunction: (index, key, value) => {
                                    if (this.isLoading.customField === false) {
                                        this.model.customFields[index][key] = !value
                                        this.isLoading.customField = true
                                        return this.$http.patch(this.apiUrl.customField + '/' + this.model.customFields[index].id,{
                                            is_active: this.model.customFields[index][key] ? 1 : 0
                                        }).then(() => {
                                            this.isLoading.customField = false
                                            this.$store.dispatch('notification/success', 'Status berhasil diubah.')
                                        }).catch(error => {
                                            this.isLoading.customField = false
                                            this.model.customFields[index][key] = !value
                                            this.$store.dispatch('notification/error', error)
                                        })
                                    }
                                }
                            },
                            {
                                key: 'actions',
                                label: ''
                            }
                        ]
                    }
                }
            }
        },
        watch: {
            '$route.params.id': function (val) {
                if (val !== undefined) {
                    this.getDetail()
                }
            }
        },
        mounted () {
            if (this.$route.params.id !== undefined) {
                this.getDetail()
                this.getSubcategories()
            }
            this.$root.$on('bv::modal::shown', (bvEvent, modalId) => {
                if (modalId === 'modal-tutorial-custom-field') {
                    this.$refs.modalTutorialCustomField.setEditorValue(this.modalModel.customField.description)
                }
            })
        },
        destroyed () {
            this.$root.$off('bv::modal::shown')
        },
        methods: {
            getDetail () {
                this.isLoading.productCategory = true
                this.$http.get(this.apiUrl.productCategory + '/' + this.$route.params.id)
                .then(response => {
                    this.model.id = response.data.id
                    this.model.parent = response.data.parent
                    this.model.name = response.data.name
                    this.model.description = response.data.description
                    this.model.sort = response.data.sort
                    this.model.logo_url = response.data.logo_url
                    this.isLoading.productCategory = false
                    // this.getVouchers()
                    // this.getCustomFields()
                }).catch(error => {
                    this.isLoading.productCategory = false
                    this.$store.dispatch('notification/error', error)
                    if (typeof error !== 'undefined' && typeof error.response !== 'undefined' && typeof error.response.status !== 'undefined' && error.response.status === 404) {
                        this.$router.push('/notfound')
                    }
                })
            },
            getSubcategories () {
                this.apiUrl.subCategory = true
                this.$http.get(this.apiUrl.productCategory + '?is_active=-1&limit=500&parent=' + this.$route.params.id)
                .then(response => {
                    this.model.subCategories = response.data.data
                    this.apiUrl.subCategory = false
                }).catch(error => {
                    this.apiUrl.subCategory = false
                    this.$store.dispatch('notification/error', error)
                })
            },
            deleteSubcategory (id) {
                this.$swal.fire({
                    title: 'Yakin ingin menghapus data ini?',
                    showCancelButton: true,
                    confirmButtonText: `Yakin`,
                    cancelButtonText: `Batal`
                }).then((result) => {
                    if (result.value) {
                        this.apiUrl.subCategory = true
                        this.$http.delete(this.apiUrl.productCategory + '/' + id).then(() => {
                            this.getSubcategories()
                            this.apiUrl.subCategory = false
                        }).catch((error) => {
                            this.apiUrl.subCategory = false
                            this.$store.dispatch('notification/error', error)
                        })
                    }
                })
            },
            // addNewVoucher () {},
            // submitFormVoucher () {
            //     this.apiUrl.subCategory = true
            //     const formData = new FormData()
            //     formData.append('parent', this.model.id);
            //     if (this.modalModel.voucher.id !== null && this.modalModel.voucher.id !== '') {
            //         formData.append('_method', 'PATCH');
            //     }
            //     if (this.modalModel.voucher.name !== null && this.modalModel.voucher.name !== '') {
            //         formData.append('name', this.modalModel.voucher.name);
            //     }
            //     if (this.modalModel.voucher.sort !== null && this.modalModel.voucher.sort !== '') {
            //         formData.append('sort', this.modalModel.voucher.sort);
            //     }
            //     if (this.modalModel.voucher.logo !== null && this.modalModel.voucher.logo !== '') {
            //         formData.append('logo', this.modalModel.voucher.logo);
            //     }
            //     this.$http.post(this.apiUrl.productCategory + (this.modalModel.voucher.id !== null && this.modalModel.voucher.id !== '' ? '/' + this.modalModel.voucher.id : ''), formData).then(() => {
            //         this.apiUrl.subCategory = false
            //         this.getVouchers()
            //         this.$bvModal.hide('modal-voucher')
            //         if (this.modalModel.voucher.id !== null && this.modalModel.voucher.id !== '') {
            //             this.$store.dispatch('notification/success', 'Voucher berhasil diubah.')
            //         } else {
            //             this.$store.dispatch('notification/success', 'Voucher berhasil disimpan.')
            //         }
            //     }).catch(error => {
            //         this.apiUrl.subCategory = false
            //         this.$store.dispatch('notification/error', error)
            //     })
            // }
            addNewCustomField () {
                this.modalModel.customField.id = null
                this.modalModel.customField.is_required = 1
                this.modalModel.customField.sort = null
                this.modalModel.customField.name = null
                this.modalModel.customField.type = 'number'
                this.$bvModal.show('modal-custom-field')
            },
            editCustomField (item) {
                this.modalModel.customField.id = item.id
                this.modalModel.customField.is_required = item.is_required ? 1 : 0
                this.modalModel.customField.sort = item.sort
                this.modalModel.customField.name = item.name
                this.modalModel.customField.type = item.type
                this.$bvModal.show('modal-custom-field')
            },
            getCustomFields () {
                this.isLoading.customField = true
                this.$http.get(this.apiUrl.customField + '?limit=100&with_fields=1&model_type=product-category&type_id=' + this.model.id)
                .then(response => {
                    if (response.data.data.length > 0) {
                        this.model.customFields = response.data.data[0].custom_fields
                    }
                    this.isLoading.customField = false
                }).catch(error => {
                    this.isLoading.customField = false
                    this.$store.dispatch('notification/error', error)
                })
            },
            submitFormCustomField (bvModalEvt) {
                bvModalEvt.preventDefault()
                if (this.$refs.customFieldForm.checkValidity() === false) {
                    this.$store.dispatch('notification/error', 'Harap lengkapi semua kolom.')
                    return false
                }
                this.isLoading.customField = true
                const formData = new FormData()
                if (this.modalModel.customField.id !== null && this.modalModel.customField.id !== '') {
                    formData.append('_method', 'PATCH')
                    formData.append('sort', this.modalModel.customField.sort)
                } else {
                    formData.append('model_type', 'product-category')
                    formData.append('type_id', this.model.id)
                }
                if (this.modalModel.customField.name !== null && this.modalModel.customField.name !== '') {
                    formData.append('name', this.modalModel.customField.name);
                }
                if (this.modalModel.customField.type !== null && this.modalModel.customField.type !== '') {
                    formData.append('type', this.modalModel.customField.type);
                }
                if (this.modalModel.customField.is_required !== null && this.modalModel.customField.is_required !== '') {
                    formData.append('is_required', this.modalModel.customField.is_required);
                }
                this.$http.post(this.apiUrl.customField + (this.modalModel.customField.id !== null && this.modalModel.customField.id !== '' ? '/' + this.modalModel.customField.id : ''), formData).then(() => {
                    this.isLoading.customField = false
                    this.getCustomFields()
                    this.$bvModal.hide('modal-custom-field')
                    if (this.modalModel.customField.id !== null && this.modalModel.customField.id !== '') {
                        this.$store.dispatch('notification/success', 'Informasi berhasil diubah.')
                    } else {
                        this.$store.dispatch('notification/success', 'Informasi berhasil disimpan.')
                    }
                }).catch(error => {
                    this.isLoading.customField = false
                    this.$store.dispatch('notification/error', error)
                })
            },
            deleteCustomField (id) {
                this.$swal.fire({
                    title: 'Yakin ingin menghapus data ini?',
                    showCancelButton: true,
                    confirmButtonText: `Yakin`,
                    cancelButtonText: `Batal`
                }).then((result) => {
                    if (result.value) {
                        this.isLoading.customField = true
                        this.$http.delete(this.apiUrl.customField + '/' + id).then(() => {
                            this.getCustomFields()
                            this.isLoading.customField = false
                            this.$store.dispatch('notification/success', 'Informasi Tambahan Berhasil Dihapus')
                        }).catch((error) => {
                            this.isLoading.customField = false
                            this.$store.dispatch('notification/error', error)
                        })
                    }
                })
            },
            editCustomFieldDescription (item) {
                this.modalModel.customField.id = item.id
                this.modalModel.customField.name = item.name
                this.modalModel.customField.description = item.description
                this.$bvModal.show('modal-tutorial-custom-field')
                // this.$refs.modalTutorialCustomField.setEditorValue(item.description)
            },
            submitFormDescriptionCustomField (bvModalEvt) {
                bvModalEvt.preventDefault()
                this.isLoading.customField = true
                this.$http.patch(this.apiUrl.customField + '/' + this.modalModel.customField.id, {
                    description: this.modalModel.customField.description
                }).then(() => {
                    this.isLoading.customField = false
                    this.getCustomFields()
                    this.$bvModal.hide('modal-tutorial-custom-field')
                    this.$store.dispatch('notification/success', 'Informasi berhasil diubah.')
                }).catch(error => {
                    this.isLoading.customField = false
                    this.$store.dispatch('notification/error', error)
                })
            },
            onEditorChange (val) {
                this.modalModel.customField.description = val === '<p><br></p>' ? null : val
            }
        }
    }
</script>