<template>
    <div>
        <b-card class="p-0 mt-4">
          <b-row class="mb-2">
            <b-col md="4">
              <h4 class="card-title">Akun Bank</h4>
            </b-col>
            <b-col md="8" class="text-right">
              <b-button v-b-popover.top.hover="'Tambah Akun Bank'" bank-account="sm" href="#/bank-accounts/create" variant="primary">
                <font-awesome-icon icon="plus" />
              </b-button>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col md="12">
              <b-form inline @submit.prevent="doSearch">
                <b-input placeholder="Cari..." v-model="model.name" />
                <b-button type="submit" class="ml-2" variant="secondary">Cari</b-button>
              </b-form>
            </b-col>
          </b-row>
          <paginated-table ref="dataTable" :can-delete="$store.getters['account/accountData'].role === 'admin'" :can-show="true" :can-edit="true" :can-approve="false" :can-reject="false" :data-url="apiUrl.bankAccount" :data-columns="fields" :default-data-params="{'is_active' : model.is_active }" :data-params="['name', 'is_active']">
          </paginated-table>
        </b-card>
    </div>
</template>
<script>
  import PaginatedTable from '@/components/PaginatedTable'
  export default {
    components: {
      PaginatedTable
    },
    data() {
      return {
        apiUrl: {
          bankAccount: process.env.VUE_APP_API_URL + 'bank-accounts'
        },
        model: {
          name: this.$route.query.name,
          is_active: -1
        },
        items: [],
        fields: [
          {
            key: 'bank_name',
            label: 'Nama'
          },
          {
            key: 'bank_account_number',
            label: 'No. Rek'
          },
          {
            key: 'bank_account_person',
            label: 'Atas Nama'
          },
          {
            key: 'is_active',
            isToggle: true,
            label: 'Status',
            thClass: 'toggle',
            tdClass: 'toggle',
            toggleFunction: (data) => {
              return this.$http.patch(this.apiUrl.bankAccount + '/' + data.id, {
                'is_active': data.value ? 1 : 0
              })
            }
          },
          { 
            key: 'actions', 
            label: '',
            thClass: 'actions',
            class: 'text-right'
          }
        ]
      }
    },
    // watch: {
    //   'currentPage' () {
    //     this.load()
    //   }
    // },
    mounted() {
      this.getUrlParams()
    },
    methods: {
      doSearch () {
        this.$refs.dataTable.doFilter(this.model)
      },
      getUrlParams () {
        this.model.name = this.model.name !== undefined ? this.model.name : null
      },
      clearField () {
        this.model.name = null
      }
    }
  }
</script>