<template>
    <div>
        <b-card class="p-0 mt-4">
          <b-row class="mb-2">
            <b-col md="4">
              <h4 class="card-title">Produk</h4>
            </b-col>
            <b-col md="8" class="text-right">
              <b-button v-b-popover.top.hover="'Tambah Produk'" size="sm" href="#/products/create" variant="primary">
                <font-awesome-icon icon="plus" />
              </b-button>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col md="9">
              <b-form inline @submit.prevent="doSearch">
                <b-input placeholder="Kode" class="mr-3" v-model="model.sku_id" />
                <b-input placeholder="Cari produk" v-model="model.name" />
                <b-button type="submit" class="ml-2" variant="secondary">Cari</b-button>
              </b-form>
            </b-col>
            <b-col md="3 text-right">
              <b-button size="md" variant="success" @click="doExportToExcel">
                <font-awesome-icon icon="download"></font-awesome-icon> Download Report
              </b-button>
            </b-col>
          </b-row>
          <paginated-table ref="dataTable" :can-delete="$store.getters['account/accountData'].role === 'admin'" :can-show="true" :can-edit="true" :can-approve="false" :can-reject="false" :data-url="apiUrl.productCategory" :data-columns="fields" :default-data-params="{'is_active' : model.is_active }" :data-params="['name', 'sku_id', 'is_active']">
          </paginated-table>
        </b-card>
    </div>
</template>
<script>
  import PaginatedTable from '@/components/PaginatedTable'
  export default {
    components: {
      PaginatedTable
    },
    data() {
      return {
        apiUrl: {
          exportProduct: process.env.VUE_APP_API_URL + 'exports/products',
          productCategory: process.env.VUE_APP_API_URL + 'products'
        },
        model: {
          sku_id: this.$route.query.sku_id,
          name: this.$route.query.name,
          is_active: -1
        },
        items: [],
        fields: [
          {
            key: 'sku_id',
            label: 'Kode',
            sortable: true
          },
          {
            key: 'name',
            label: 'Nama',
            sortable: true
          },
          {
            key : 'category',
            label : 'Kategori',
            sortable : true
          },
          {
            key : 'quantity',
            label : 'Sisa Stok',
            sortable : true
          },
          {
            key: 'is_active',
            isToggle: true,
            label: 'Status',
            thClass: 'toggle',
            tdClass: 'toggle',
            toggleFunction: (data) => {
              return this.$http.patch(this.apiUrl.productCategory + '/' + data.id, {
                'is_active': data.value ? 1 : 0
              })
            }
          },
          { 
            key: 'actions', 
            label: '',
            thClass: 'actions',
            class: 'text-right'
          }
        ]
      }
    },
    // watch: {
    //   'currentPage' () {
    //     this.load()
    //   }
    // },
    mounted() {
      this.getUrlParams()
    },
    methods: {
      doSearch () {
        this.$refs.dataTable.doFilter(this.model)
      },
      getUrlParams () {
        this.model.category = this.model.category !== undefined ? this.model.category : null
        this.model.sku_id = this.model.sku_id !== undefined ? this.model.sku_id : null
        this.model.name = this.model.name !== undefined ? this.model.name : null
      },
      clearField () {
        this.model.category = null
        this.model.name = null
      },
      doExportToExcel () {
        let url = this.apiUrl.exportProduct
        if (this.model.name !== undefined && this.model.name !== null) {
          url += '?name=' + this.model.name
        }
        window.open(url)
      }
    }
  }
</script>