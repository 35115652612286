<template>
    <div>
    <b-breadcrumb :items="[{
        text: 'Daftar Produk',
        href: '#/products'
    }, {
        text: 'Detail',
        active: true
    }]"></b-breadcrumb>
    <b-card>
        <b-row>
            <b-col md="6">
                <b-row>
                    <b-col md="12">
                        <h3 class="page-title mb-0"> {{model.name}}</h3>
                    </b-col>
                    <b-col md="6" class="text-right">
                        <b-button v-if="model.is_active === false" type="button" size="md" @ variant="success" @click.prevent="publishProduct"><font-awesome-icon icon="check" /> Publis Produk</b-button>
                    </b-col>
                </b-row>
            </b-col>
            <b-col md="6" class="mt-3 text-right">
                <b-button :href="'#/products/'+$route.params.id+'/edit'" size="sm" variant="secondary"><font-awesome-icon icon="edit" /> Edit</b-button>
            </b-col>
            <!-- <b-col md="6" class="mt-3">
                <b-row>
                    <b-col md="4">
                        <strong>Tipe</strong>
                    </b-col>
                    <b-col md="8">
                        {{model.type === 1 ? 'Tunggal' : 'Varian'}}
                    </b-col>
                </b-row>
            </b-col> -->
            <b-col md="8" class="mt-3">
                <b-row >
                    <b-col md="4">
                        <strong>Kode Produk</strong>
                    </b-col>
                    <b-col md="8">
                        {{model.sku_id !== null ? model.sku_id: '-'}}
                    </b-col>
                </b-row>
            </b-col>
            <b-col md="8" class="mt-3">
                <b-row >
                    <b-col md="4">
                        <strong>Kategori</strong>
                    </b-col>
                    <b-col md="8">
                        {{model.product_category !== null ? model.product_category.name : '-'}}
                    </b-col>
                </b-row>
            </b-col>
             <b-col md="8" class="mt-3">
                <b-row >
                    <b-col md="4">
                        <strong>Harga Pembelian</strong>
                    </b-col>
                    <b-col md="8">
                        {{model.purchase_price !== null ? model.purchase_price : '-'}}
                    </b-col>
                </b-row>
            </b-col>
             <b-col md="8" class="mt-3">
                <b-row >
                    <b-col md="4">
                        <strong>Harga Jual</strong>
                    </b-col>
                    <b-col md="8">
                        {{model.selling_price !== null ? model.selling_price : '-'}}
                    </b-col>
                </b-row>
            </b-col>
             <b-col md="8" class="mt-3">
                <b-row >
                    <b-col md="4">
                        <strong>Sisa Stok</strong>
                    </b-col>
                    <b-col md="8">
                        <template v-if="isEditQuantity">
                            <b-form inline>
                                <b-input placeholder="Ganti" type="number" v-model="model.quantity" />
                                <b-button type="button" @click="isEditQuantity = false" class="ml-2" variant="danger">
                                    <font-awesome-icon icon="times" />
                                </b-button>
                                <b-button type="submit" @click="doUpdate" class="ml-2" variant="success">
                                    <font-awesome-icon icon="check" />
                                </b-button>
                            </b-form>
                        </template>
                        <template v-else>
                            <div class="cursor-pointer">{{model.quantity !== null ? model.quantity : '-'}}
                                <b-button type="submit" v-b-popover.top.hover="'Edit Stok'"  @click="isEditQuantity = true" class="ml-2" size="sm" variant="secondary">
                                    <font-awesome-icon icon="pencil-alt" />
                                </b-button>
                            </div>
                        </template>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-card>
    </div>
</template>
<script>
    export default {
        data () {
            return {
                isLoading: {
                    product: true
                },
                apiUrl: {
                    product: process.env.VUE_APP_API_URL + 'products'
                },
                isEditQuantity: false,
                model: {
                    id: null,
                    name: null,
                    product_category: null,
                    selling_price: null,
                    purchase_price: null,
                    quantity: null,
                    sku_id: null
                }
            }
        },
        watch: {
            '$route.params.id': function (val) {
                if (val !== undefined) {
                    this.getDetail()
                }
            }
        },
        mounted () {
            if (this.$route.params.id !== undefined) {
                this.getDetail()
            }
        },
        methods: {
            getDetail () {
                this.isLoading = true
                this.$http.get(this.apiUrl.product + '/' + this.$route.params.id)
                .then(response => {
                    this.model.id = response.data.id
                    this.model.name = response.data.name
                    this.model.product_category = response.data.category
                    this.model.selling_price = response.data.selling_price
                    this.model.purchase_price = response.data.purchase_price
                    this.model.quantity = response.data.quantity
                    this.model.sku_id = response.data.sku_id
                    this.isLoading = false
                }).catch(error => {
                    this.isLoading = false
                    this.$store.dispatch('notification/error', error)
                    if (typeof error !== 'undefined' && typeof error.response !== 'undefined' && typeof error.response.status !== 'undefined' && error.response.status === 404) {
                        // this.$router.push('/notfound')
                    }
                })
            },
            doUpdate () {
                this.isLoading = true
                this.$http.patch(this.apiUrl.product + '/' + this.$route.params.id + '/quantity', {
                    quantity: this.model.quantity
                })
                .then(() => {
                    this.getDetail()
                    this.isEditQuantity = false
                    this.isLoading = false
                }).catch(error => {
                    this.isLoading = false
                    this.$store.dispatch('notification/error', error)
                    if (typeof error !== 'undefined' && typeof error.response !== 'undefined' && typeof error.response.status !== 'undefined' && error.response.status === 404) {
                        // this.$router.push('/notfound')
                    }
                })
            }
        }
    }
</script>