<template>
  <div>
  <b-breadcrumb :items="[{
        text: 'Pengaturan Format Komentar',
        active: true
  }]"></b-breadcrumb>
  <b-card title="Pengaturan Format Komentar">
    <b-form @submit.prevent="onSubmit">
      <b-row>
        <b-col md="6">
                <b-form-group id="input-group-1" label="Awalan Komentar*:" label-for="input-1">
                    <b-form-input
                    id="input-1"
                    v-model="model.prefix_word"
                    placeholder="Ketikkan Awalan Komentar"
                    required
                    ></b-form-input>
                    <small><em>kolom: harus mengunakan huruf kecil!</em></small>
                </b-form-group>
                <b-form-group id="input-group-3" label="Tanda Pemisah*:" label-for="input-3">
                    <b-form-select
                    id="input-3"
                    :options="options.separate_by"
                    v-model="model.separate_by"
                    required
                    ></b-form-select>
                </b-form-group>
                <b-form-group id="input-group-2" label="Kolom Order:*" label-for="input-2">
                    <b-button class="mr-2" @click="toggleFormatList(format_list.value)" v-for="format_list in options.format_list" :key="format_list.value" :variant="model.enable_format_list.includes(format_list.value) ? 'success' : 'secondary'">{{format_list.text}}</b-button>
                    <br>
                    <small>Klik untuk mengaktifkan kolom.</small>
                </b-form-group>
                <!-- <b-form-group id="input-group-4" label="Preview Format Komentar:" label-for="input-4">
                    {{finalFormat}}
                </b-form-group> -->
        </b-col>
        <b-col md="6">
            <h5>Preview Format Komentar</h5>
            <div id="comment-preview">
                <!-- <div id="live-preview" style="
                    background: #000;
                    min-height: 200px;
                    padding: 10px 15px;
                    display: flex;
                    flex-wrap: wrap;
                    ">
                    <div style="
                        width: 100%;
                        margin-top: auto;">
                        <h3 style="
                            color: #fff;
                            padding: 0;
                            margin-bottom: 0;
                            font-size: 1.25rem;
                            ">Live Malam Malam Asik Yuk</h3>
                        <p style="
                            color: #fff;
                            margin: 0px;
                            ">Mekro.id</p>
                    </div>
                </div> -->
                <div id="comment-preview" style="
                    margin-top: 10px;
                    ">
                    <div class="comment-box" style="
                        display: flex;
                        flex-wrap: wrap;
                        ">
                        <div class="comment-photo" style="
                            width: 50px;
                            height: 50px;
                            margin-right: 5px;
                            background: #6d757d;
                            border-radius: 100%;
                            "></div>
                        <div class="comment-content" style="
                            background: #f0f2f5;
                            padding: 9px 13px;
                            border-radius: 18px;
                            ">
                            <h4 style="
                            font-size: 1rem;
                            margin-bottom: 3px;
                            ">Afgan Syahreza</h4>
                            <p style="
                            font-size: 1rem;
                            margin: 0px;
                            ">{{finalFormat.length > 1 ? finalFormat : 'Anda belum menentukan format komentar'}}</p>
                        </div>
                        <div class="comment-button" @click.prevent="" style="
                                width: 100%;
                                margin-left: 39px;
                            "><a href="#" style="
                                color: #6d757d;
                                font-size: 12px;
                                font-weight: 600;
                                margin-right: 5px;
                            ">Like</a><a href="#" @click.prevent="" style="
                                color: #6d757d;
                                font-size: 12px;
                                font-weight: 600;
                                margin-right: 5px;">Reply</a><span style="
                                color: #6d757d;
                                font-size: 12px;
                            ">1m</span>
                        </div>
                    </div>
                </div>
                </div>
        </b-col>
      </b-row>
        <b-button class="mt-3" type="submit" variant="primary" :disabled="isLoading"><font-awesome-icon v-if="isLoading"  icon="spinner" spin /> Simpan</b-button>
    </b-form>
  </b-card>
  </div>
</template>
<script>
    import { commentSeparateByList, commentFormatList } from '@/_helpers'
    export default {
        data () {
            return {
                isLoading: false,
                apiUrl: {
                    webConfigs: process.env.VUE_APP_API_URL + 'web-configs?group=comment-formats'
                },
                model: {
                    prefix_word: null,
                    separate_by: ' ',
                    enable_format_list: []
                },
                options: {
                    separate_by: commentSeparateByList(),
                    format_list: commentFormatList()
                }
            }
        },
        computed: {
            finalFormat: function () {
                const separate_by = this.model.separate_by === 'space' ? ' ' : this.model.separate_by
                let comment = (this.model.prefix_word !== null ? this.model.prefix_word : '') + (this.model.separate_by !== null ? separate_by: '') + (this.model.enable_format_list.length ? this.model.enable_format_list.join(separate_by) : '')
                this.options.format_list.forEach(format_list => {
                    comment = comment.replace(format_list.value, format_list.replaceWord)
                })
                return comment
            }
        },
        mounted () {
            this.getDetail()
        },
        methods: {
            toggleFormatList (val) {
                const r = this.model.enable_format_list.indexOf(val)
                if(r === -1) {
                    this.model.enable_format_list.push(val)
                } else {
                    this.model.enable_format_list.splice(r, 1)
                }
            },
            onSubmit () {
                if (this.model.enable_format_list.length === 0) {
                    this.$store.dispatch('notification/error', 'Kolom Order wajib Diisi')
                    return ;
                }
                this.isLoading = true
                this.$http.post(this.apiUrl.webConfigs, {
                    prefix_word: this.model.prefix_word,
                    separate_by: this.model.separate_by,
                    enable_format_list: this.model.enable_format_list.join(',')
                }).then(response => {
                    this.isLoading = false
                    this.$store.dispatch('notification/success', 'Pengaturan berhasil diubah.')
                }).catch(error => {
                    this.isLoading = false
                    this.$store.dispatch('notification/error', error)
                })
            },
            getDetail () {
                this.isLoading = true
                this.$http.get(this.apiUrl.webConfigs)
                .then(response => {
                    response.data.data.forEach(resp => {
                        let val = resp.value
                        if (resp.type === 'list') {
                            if (val === null) {
                                val = []
                            } else {
                                val = val.split(',')
                            }
                        }
                        this.model[resp.key] = val
                    })
                    this.isLoading = false
                }).catch(error => {
                    this.isLoading = false
                    this.$store.dispatch('notification/error', error)
                    if (typeof error !== 'undefined' && typeof error.response !== 'undefined' && typeof error.response.status !== 'undefined' && error.response.status === 404) {
                        this.$router.push('/notfound')
                    }
                })
            }
        }
    }
</script>