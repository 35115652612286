<template>
    <div>
        <b-card class="p-0 mt-4">
          <b-row class="mb-2">
            <b-col md="4">
              <h4 class="card-title">Staff</h4>
            </b-col>
            <b-col md="8" class="text-right">
              <b-button v-b-popover.top.hover="'Tambah Staff'" size="sm" href="#/users/create" variant="primary">
                <font-awesome-icon icon="plus" />
              </b-button>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col md="12">
              <b-form inline @submit.prevent="doSearch">
                <b-input placeholder="Cari nama" class="mr-2" v-model="model.name" />
                <b-input placeholder="Cari email" v-model="model.email" />
                <b-button type="submit" class="ml-2" variant="secondary">Cari</b-button>
              </b-form>
            </b-col>
          </b-row>
          <paginated-table ref="dataTable" :can-delete="$store.getters['account/accountData'].role === 'admin'" :can-show="true" :can-edit="true" :can-approve="false" :can-reject="false" :data-url="apiUrl.banner" :data-columns="fields" :default-data-params="{'role': 'staff' }" :data-params="['name', 'email', 'role']">
          </paginated-table>
        </b-card>
    </div>
</template>
<script>
  import PaginatedTable from '@/components/PaginatedTable'
  export default {
    components: {
      PaginatedTable
    },
    data() {
      return {
        apiUrl: {
          banner: process.env.VUE_APP_API_URL + 'users'
        },
        model: {
          name: this.$route.query.name,
          email: this.$route.query.email,
          role: 'staff'
        },
        items: [],
        fields: [
          {
            key: 'name',
            label: 'Nama'
          },
          {
            key: 'email',
            label: 'Email'
          },
          {
            key: 'whatsapp_number',
            label: 'No. WA'
          },
          {
            key: 'status',
            isToggle: true,
            label: 'Status',
            thClass: 'toggle',
            tdClass: 'toggle',
            toggleFunction: (data) => {
              return this.$http.patch(this.apiUrl.banner + '/' + data.id, {
                'status': data.value ? 1 : 0
              })
            }
          },
          { 
            key: 'actions', 
            label: '',
            thClass: 'actions',
            class: 'text-right'
          }
        ]
      }
    },
    methods: {
      doSearch () {
        this.$refs.dataTable.doFilter(this.model)
      },
      getUrlParams () {
        this.model.name = this.model.name !== undefined ? this.model.name : null
        this.model.email = this.model.email !== undefined ? this.model.email : null
      },
      clearField () {
        this.model.name = null
        this.model.email = null
      }
    }
  }
</script>