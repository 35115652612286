<template>
    <div>
        <b-modal id="add-new-live" :hide-footer="video.pageLoading" no-close-on-esc no-close-on-backdrop @ok="submitForm" ok-title="Tambah" cancel-title="Batal" title="Tambah Sesi Live">
            <form @submit.prevent="submitForm" ref="videoForm">
                <h5 v-if="video.pageLoading" class="text-center"><font-awesome-icon icon="spinner" spin class="mr-1" /> Memuat data</h5>
                <b-form-group v-else id="input-video-fp-group" label="Halaman FP sumber video" label-for="input-video-fp">
                    <b-input-group v-if="!video.editPageID">
                        <b-form-input v-model="model.page_name" disabled/>
                        <b-input-group-append>
                            <b-button @click="enableEditPage()" variant="primary"><font-awesome-icon icon="pencil-alt" /></b-button>
                        </b-input-group-append>
                    </b-input-group>
                    <b-form-select v-else required v-model="model.page_id" :options="options.pages" :state="model.page_id !== null && model.page_id !== ''"></b-form-select>
                    <p v-if="!video.canEditFBPage"><small>Jika list diatas kosong, berarti anda belum menyetujui akses salah satu FP ketika login.</small></p>
                </b-form-group>
            </form>
        </b-modal>
    </div>
</template>
<script>
  export default {
    data() {
      return {
        video: {
            editPageID: false,
            pageLoading: false,
            canEditFBPage: true,
            pageList: []
        },
        options: {
            pages: []
        },
        model: {
            page_id: null,
            page_name: null
        }
      }
    },
    // mounted() {
    //     this.open()
    // },
    methods: {
        open () {
            this.model.page_id = localStorage.getItem('page_id')
            this.model.page_name = localStorage.getItem('page_name')
            if (this.model.page_id !== null && this.model.page_id !== '') {
                this.video.editPageID = false
            } else {
                this.video.editPageID = true
            }
            this.$bvModal.show('add-new-live')
            if (this.video.editPageID) {
                this.loadPageList()
            }
        },
        enableEditPage () {
            this.video.editPageID = true
            this.loadPageList()
        },
        loadPageList () {
            if (this.options.pages.length === 0) {
                this.video.pageLoading = true
                window.FB.api('/' + this.$store.getters['facebook/getAccountID'] + '/accounts', (response) => {
                    if (!response || response.error) {
                        this.video.canEditFBPage = false
                        this.$store.dispatch('notification/error', !response ? 'Gagal memuat halaman' : response.error)
                    } else {
                        this.video.canEditFBPage = true
                        this.options.pages = [{
                            value: null,
                            text: 'Pilih satu halaman FP sumber video'
                        }]
                        response.data.forEach(d => {
                            this.options.pages.push({
                            value: d.id,
                            text: d.name
                            })
                        })
                    }
                    this.video.pageLoading = false
                })
            }
        },
        submitForm(bvModalEvt) {
            bvModalEvt.preventDefault()
            if (this.$refs.videoForm.checkValidity() === false) {
                this.$store.dispatch('notification/error', 'Harap lengkapi semua kolom.')
                return false
            }
            if (this.options.pages.length > 0 && this.model.page_id !== null) {
                this.model.page_name = this.options.pages.find(option => option.value === this.model.page_id).text
            }
            localStorage.setItem('page_id', this.model.page_id)
            localStorage.setItem('page_name', this.model.page_name)
            this.$nextTick(() => {
                this.$bvModal.hide('add-new-live')
            })
            this.$emit('onsubmit', this.model.page_id)
        }
    }
  }
</script>