<template>
  <div>
  <b-breadcrumb :items="[{
        text: 'Pengaturan WhatsApp',
        active: true
  }]"></b-breadcrumb>
  <b-card title="WhatsApp">
      <template v-if="this.isLoading.status">
          <font-awesome-icon icon="spinner" spin /> Checking Status WA
      </template>
      <template v-if="!this.isLoading.status">
        <h5 class="mt-4">Status WA <b-badge :variant="model.online ? 'success' : 'danger'">{{model.online ? 'Online' : 'Offline'}}</b-badge></h5>
        <!-- <template v-if="!model.online">
            <h5 class="mt-4">Scan Untuk Login WA <small v-if="!isLoading.qrcode"><a href="#" @click.prevent="getQrCode">Refresh QrCode</a></small></h5>
            <p v-if="isLoading.qrcode"><font-awesome-icon icon="spinner" spin /> Memuat QRCode</p>
            <img v-if="!isLoading.qrcode" :src="model.qrcode" class="img-fluid">
        </template> -->
        <b-button :disabled="isLoading.logout" v-if="model.online" @click.prevent="doLogout" class="mt-4" size="lg" variant="warning"><font-awesome-icon v-if="isLoading.logout" icon="spinner" spin /> Logout WA</b-button>
      </template>
  </b-card>
  </div>
</template>
<script>
    export default {
        data () {
            return {
                isLoading: {
                    qrcode: false,
                    status: false,
                    logout: false
                },
                apiUrl: {
                    WhatsApp: process.env.VUE_APP_API_URL + 'whats-app'
                },
                model: {
                    qrcode: null,
                    online: false
                }
            }
        },
        mounted () {
            // this.getQrCode()
            this.getStatus()
        },
        methods: {
            getQrCode () {
                this.isLoading.qrcode = true
                this.$http.get(this.apiUrl.WhatsApp + '/qrcode')
                .then(response => {
                    console.log(response.data)
                    this.model.qrcode = response.data
                    this.isLoading.qrcode = false
                }).catch(error => {
                    this.isLoading.qrcode = false
                    this.$store.dispatch('notification/error', error)
                })
            },
            getStatus () {
                this.isLoading.status = true
                this.$http.get(this.apiUrl.WhatsApp + '/status')
                .then(response => {
                    this.model.online = response.data
                    this.isLoading.status = false
                }).catch(error => {
                    this.isLoading.status = false
                    this.$store.dispatch('notification/error', error)
                })
            },
            doLogout () {
                if (confirm('Anda yakin akan logout WA?')) {
                    this.isLoading.logout = true
                    this.$http.post(this.apiUrl.WhatsApp + '/logout')
                    .then(() => {
                        this.getStatus()
                        this.isLoading.logout = false
                    }).catch(error => {
                        this.isLoading.logout = false
                        this.$store.dispatch('notification/error', error)
                    })
                }
            }
        }
    }
</script>