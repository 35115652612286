<template>
    <div>
      <b-table
        responsive
        ref="table"
        :busy="isLoading"
        selectedVariant='link'
        selectable
        striped
        @loading="onTableLoading"
        @loaded="onTableLoaded"
        select-mode="single"
        :items="tableData"
        :fields="dataColumns"
        :current-page="pagination.currentPage"
        show-empty
        :tbody-tr-class="rowClass"
      >
        <template v-slot:empty="">
          <div class="text-center bd-secondary">
            <span>There's no data</span>
          </div>
        </template>
        <template v-slot:table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong> Loading...</strong>
          </div>
        </template>
        <template v-slot:cell(file_url)="row">
          <div style="text-align: center;">
            <a :href="row.item.file_url" target="_blank">
              <img :src="row.item.file_url" style="max-width: 100px;" class="img-fluid" />
            </a>
          </div>
        </template>
        <template v-slot:cell(company)="row">
          {{row.item.company.name}}
        </template>
        <template v-slot:cell(category)="row">
          {{row.item.category.name}}
        </template>
        <template v-slot:cell(url_fb)="row">
          <a :href="row.item.url_fb" v-if="row.item.url_fb !== null" target="_blank">{{row.item.url_fb}}</a>
          <span v-else>-</span>
        </template>
        <template #head(started_at)="data">
            <span style=";text-align: center;width: 100%;display: block;">{{data.label}}</span>
        </template>
        <template #head(finished_at)="data">
            <span style=";text-align: center;width: 100%;display: block;">{{data.label}}</span>
        </template>
        <template #head(live_status)="data">
            <span style=";text-align: center;width: 100%;display: block;">{{data.label}}</span>
        </template>
        <template #head(created_at)="data">
            <span style=";text-align: center;width: 100%;display: block;">{{data.label}}</span>
        </template>
        <template #head(due_datetime)="data">
            <span style=";text-align: center;width: 100%;display: block;">{{data.label}}</span>
        </template>
        <template #head(quantity)="data">
            <span style=";text-align: center;width: 100%;display: block;">{{data.label}}</span>
        </template>
        <template #head(order_status)="data">
            <span style=";text-align: center;width: 100%;display: block;">{{data.label}}</span>
        </template>
        <template v-slot:cell(start_at)="row">
          <div style="text-align: center;">
            {{getDateTime(row.item.start_at)}}
          </div>
        </template>
        <template v-slot:cell(finish_at)="row">
          <div style="text-align: center;">
            {{getDateTime(row.item.finish_at)}}
          </div>
        </template>
        <template v-slot:cell(created_at)="row">
          <div style="text-align: center;">
            {{getDateTime(row.item.created_at)}}
          </div>
        </template>
        <template v-slot:cell(started_at)="row">
          <div style="text-align: center;">
            {{getDateTime(row.item.started_at)}}
          </div>
        </template>
        <template v-slot:cell(finished_at)="row">
          <div style="text-align: center;">
            {{row.item.finished_at === null ? '-' : getDateTime(row.item.finished_at)}}
          </div>
        </template>
        <template v-slot:cell(live_status)="row">
          <div style="text-align: center;">
            <h5 class="p-0 m-0"><b-badge v-if="row.item.finished_at === null" variant="danger" class="p-2" style="font-weight: normal" size="md">
              Live
            </b-badge>
            <b-badge v-else variant="success" class="p-2" style="font-weight: normal" size="sm">
              Selesai
            </b-badge>
            </h5>
          </div>
        </template>
        <template v-slot:cell(due_datetime)="row">
          <div style="text-align: center;">
            {{getDateTime(row.item.due_datetime)}}
          </div>
        </template>
        <template v-slot:cell(order_voucher_type)="row">
          <div style="text-align: left;">
            {{row.item.details[0].product.category !== null ? row.item.details[0].product.category.parent_category.name + '-' + row.item.details[0].product.category.name : '-'}}
          </div>
        </template>
        <template #head(order_nominal)="data">
            <span style=";text-align: right;width: 100%;display: block;">{{data.label}}</span>
        </template>
        <template #head(payment_fee)="data">
            <span style=";text-align: right;width: 100%;display: block;">{{data.label}}</span>
        </template>
        <template v-slot:cell(order_nominal)="row">
          <div style="text-align: right;">
            {{row.item.details[0].product.name}}
          </div>
        </template>
        <template #head(total)="data">
            <span style=";text-align: right;width: 100%;display: block;">{{data.label}}</span>
        </template>
        <template #head(start_at)="data">
            <span style=";text-align: center;width: 100%;display: block;">{{data.label}}</span>
        </template>
        <template #head(finish_at)="data">
            <span style=";text-align: center;width: 100%;display: block;">{{data.label}}</span>
        </template>
        <template v-slot:cell(total)="row">
          <div style="text-align: right;">
            {{$formatPrice(row.item.total)}}
          </div>
        </template>
        <template v-slot:cell(order_actions)="row">
          <div style="text-align: center;">
            <b-button v-if="canShow === true && customShow === false" variant="primary" @click="showModalDetailOrder(row.item)" size="sm">
              <font-awesome-icon icon="search-plus" class="text-light fa-1x" />
            </b-button>
            <b-button v-else variant="primary" size="sm" :to="$route.path + (customUrlDetail !== '' ? customUrlDetail : '') + '/' + row.item.id" class="mr-1 box-shadow">
              <font-awesome-icon icon="external-link-alt" class="text-light fa-1x" />
            </b-button>
          </div>
        </template>
        <template v-slot:cell(user)="row">
          <div>
            <template v-if="row.item.user !== null">
              {{row.item.user.name}} <br> {{row.item.user.whatsapp_number}}
            </template>
            <template v-else>
              {{row.item.buyer_note}}
            </template>
          </div>
        </template>
        <template v-slot:cell(order_status)="row">
          <div style="text-align: center;">
            <b-badge v-if="row.item.status === 0" variant="danger" class="p-2" style="font-weight: normal" size="sm">
              Batal
            </b-badge>
            <b-badge v-else-if="row.item.status === 1" variant="secondary" class="p-2" style="font-weight: normal" size="sm">
              Menunggu
            </b-badge>
            <b-badge v-else-if="row.item.status === 2" variant="warning" class="p-2" style="font-weight: normal" size="sm">
              Ditagih
            </b-badge>
            <b-badge v-else-if="row.item.status === 3" variant="success" class="p-2" style="font-weight: normal" size="sm">
              Selesai
            </b-badge>
          </div>
        </template>
        <template v-slot:cell(file_manager_actions)="row">
          <div class="text-center">
            <input style="z-index: -10; opacity:0;;position: absolute;" type="text" :value="row.item.file_url" :id="'my_input_' + row.item.id">
            <b-button v-b-tooltip.hover.topleft title="Copy ke clipboard" variant="primary" size="sm" class="mr-1 box-shadow" @click.prevent="copyToClipboard('my_input_' + row.item.id)">
              <font-awesome-icon icon="clipboard" class="text-light fa-1x" />
            </b-button>
            <b-button v-if="canDelete" variant="danger" size="sm" @click.prevent="doDeleteData(row.item.id)" class="box-shadow">
              <font-awesome-icon icon="trash" class="text-light fa-1x" />
            </b-button>
          </div>
        </template>
        <template v-slot:cell(employementName)="row">
          <div class="">
            {{row.item.employment !== null ? row.item.employment.title : '-'}}
          </div>
        </template>
        <template v-slot:cell(title)="row">
          <div class="">
            <div v-b-tooltip.hover.bottom :title="row.item.title">
              {{row.item.title.slice(0, 35)}}...
            </div>
          </div>
        </template>
        <template v-slot:cell(product_sku)="row">
          <div class="">
            {{row.item.details.length > 0 ? row.item.details[0].product.sku_id : '-'}}
          </div>
        </template>
        <template v-slot:cell(product_name)="row">
          <div class="">
            {{row.item.details.length > 0 ? row.item.details[0].product.name : '-'}}
          </div>
        </template>
        <template v-slot:cell(product_qty)="row">
          <div class="">
            {{row.item.details.length > 0 ? row.item.details[0].quantity : '-'}}
          </div>
        </template>
        <template v-slot:cell(product_selling_price)="row">
          <div class="">
            {{row.item.details.length > 0 ? row.item.details[0].price : '-'}}
          </div>
        </template>
        <template v-slot:cell(order_detail_subtotal)="row">
          <div class="">
            {{row.item.details.length > 0 ? row.item.details[0].total : '-'}}
          </div>
        </template>
        <template v-slot:cell(quantity)="row">
          <div class="text-center">
            {{row.item.quantity}}
          </div>
        </template>
        <template v-slot:cell(userAddress)="row">
          <div class="text-center">
            {{row.item.user !== null ? row.item.user.address : '-'}}
          </div>
        </template>
        <template v-slot:cell(payment_channel_name)="row">
          <div style="display: flex;align-items: center;">
            <div style="margin-right: 10px;">
              <img :src="row.item.logo_url" style="max-width: 80px;" alt="">
            </div>
            <div class="">
              <small>{{row.item.payment_method.name}}</small><br>{{row.item.name}}
            </div>
          </div>
        </template>
        <template v-slot:cell(payment_fee)="row">
          <div style="text-align: right">
            {{parseFloat(row.item.fee) + 0}}
            <b-button variant="secondary" @click.prevent="orderAction(row)" size="sm" class="ml-2" v-b-tooltip.hover.topleft title="Ubah Fee">
              <font-awesome-icon icon="edit" class="text-light fa-1x" />
            </b-button>
          </div>
        </template>
        <template v-slot:cell(is_active)="row">
          <toggle-button :sync="true" :value="row.item.is_active" @change="toggleFunction(row.field.toggleFunction, row.index,row.field.key, row.item.is_active)"/>
        </template>
        <template v-slot:cell(status)="row">
          <toggle-button :sync="true" :value="!!row.item.status" @change="toggleFunction(row.field.toggleFunction, row.index,row.field.key, row.item.status)"/>
        </template>
        <template v-slot:cell(actions)="row">
          <div class="text-right">
            <b-button v-if="canShow" variant="primary" size="sm" :to="$route.path + (customUrlDetail !== '' ? customUrlDetail : '') + '/' + row.item.id" class="mr-1 box-shadow">
              <font-awesome-icon icon="external-link-alt" class="text-light fa-1x" />
            </b-button>
            <b-button v-if="canEdit" variant="success" size="sm" :to="$route.path + '/' + row.item.id +'/edit'" class="mr-1 box-shadow">
              <font-awesome-icon icon="edit" class="text-light fa-1x" />
            </b-button>
            <b-button v-if="canDelete" variant="danger" size="sm" @click.prevent="doDeleteData(row.item.id)" class="box-shadow">
              <font-awesome-icon icon="trash" class="text-light fa-1x" />
            </b-button>
          </div>
        </template>
      </b-table>
      <b-row class="mt-2 p-2" v-if="pagination.total > dataLimit">
        <div class="col-md-6 pr-0">
        <span class="card-category"
          >Showing {{ from + 1 }} to {{ to }} of
          {{ pagination.total }} entries</span
        >
        </div>
        <b-col sm="6" md="6" class="my-1 pl-0">
        <b-pagination
          v-model="pagination.currentPage"
          :total-rows="pagination.total"
          :per-page="dataLimit"
          align="fill"
          size="sm"
          class="my-0 float-right"
        ></b-pagination>
        </b-col>
      </b-row>
      <b-modal id="bv-modal-example" hide-footer>
        <template v-slot:modal-title>
            <h5 class="text-transform">{{type}} {{name}}</h5>
        </template>
        <form v-on:submit.prevent="doChangeStatus(type)">
            <div class="d-block">
                <label for=""><strong>Note</strong></label>
                <b-form-textarea rows="3" v-model="formData.note" placeholder="Enter note"></b-form-textarea>
            </div>
            <b-button class="mt-4" @click.prevent="$bvModal.hide('bv-modal-example')"><font-awesome-icon icon="times"></font-awesome-icon> Cancel</b-button>
            <b-button variant="primary" type="submit" class="mt-4 float-right"><font-awesome-icon icon="spinner" spin v-if="isLoading"></font-awesome-icon> <font-awesome-icon icon="save" v-else></font-awesome-icon> Submit</b-button>
        </form>
      </b-modal>
      <b-modal id="bv-modal-order-detail" size="lg"  hide-footer>
        <template v-slot:modal-title>
            <h5 class="text-transform">Detail Order</h5>
        </template>
        <OrderDetailComponent :dataDetailOrder="dataDetailOrder" v-on:doResfreshOrderList="getDataTable"/>
      </b-modal>
    </div>
</template>
<script>
  import OrderDetailComponent from './OrderDetailComponent'
  export default {
    components: {
      OrderDetailComponent
    },
    props: {
      dataUrl: {
        type: String,
        required: true
      },
      deleteUrl: {
        type: String,
        required: false,
        default: null
      },
      // dataKey: {
      //   type: String,
      //   required: true
      // },
      dataColumns: {
        type: Array,
        required: true
      },
      customUrlDetail: {
        type: String,
        required: false,
        default: ''
      },
      dataOrderField: {
        type: String,
        default: 'createdAt'
      },
      dataOrderAsc: {
        type: Number,
        default: 0
      },
      dataLimit: {
        type: Number,
        default: 30
      },
      dataParams: {
        type: Array,
        required: false,
        default: function () {
          return []
        }
      },
      modalView: {
        type: Boolean,
        required: false,
        default: false
      },
      defaultDataParams: {
        type: Object,
        required: false,
        default: function () {
          return {}
        }
      },
      canShowDocument: {
        type:Boolean,
        required: false,
        default: false
      },
      canShow: {
        type: Boolean,
        required: false,
        default: true
      },
      canShowRoutePath: {
        type: Boolean,
        required: false,
        default: false
      },
      customShow: {
        type: Boolean,
        required: false,
        default: false
      },
      canEdit: {
        type: Boolean,
        required: false,
        default: true
      },
      canEditModal: {
        type: Boolean,
        required: false,
        default: false
      },
      canDelete: {
        type: Boolean,
        required: false,
        default: true
      },
      canApprove: {
        type: Boolean,
        required: false,
        default: true
      },
      canReject: {
        type: Boolean,
        required: false,
        default: true
      },
      noActions: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    data () {
      return {
        isLoading: false,
        failedToGetData: false,
        name: this.$route.name,
        urlName: null,
        modelid: null,
        pagination: {
          // dataLimit: 20,
          currentPage: this.$route.query.page !== undefined ? parseInt(this.$route.query.page) : 1,
          total: 0,
          order: null,
          ascending: 0
        },
        propsToSearch: ['name', 'email', 'age'],
        tableColumns: [],
        tableData: [],
        dataDetailOrder: null,
        formData: {
            note: null
        },
        customSlots: []
      }
    },
    computed: {
      from () {
        return this.dataLimit * (this.pagination.currentPage - 1)
      },
      to () {
        let highBound = this.from + this.dataLimit
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      }
    },
    watch: {
      'pagination.currentPage' () {
        this.getDataTable()
      }
    },
    methods: {
      toggleFunction (callbackFunction, index, key, value) {
        if (this.isLoading === false) {
          this.tableData[index][key] = !value
          // this.isLoading = true
          callbackFunction({
            'id': this.tableData[index].id, 'key': key, 'value': this.tableData[index][key]
          }).then(() => {
            this.isLoading = false
            this.$store.dispatch('notification/success', 'Data berhasil diubah.')
          }).catch(error => {
            this.isLoading = false
            this.tableData[index][key] = !!value
            this.$store.dispatch('notification/error', error)
          })
        }
      },
      copyToClipboard(idInput) {
        /* Get the text field */
        const copyText = document.getElementById(idInput)
        /* Select the text field */
        copyText.select()
        copyText.setSelectionRange(0, 99999) /*For mobile devices*/
        /* Copy the text inside the text field */
        document.execCommand('copy')
        /* Alert the copied text */
        this.$store.dispatch('notification/success', 'Url di copy ke clipboard.')
      },
      rowClass(item, type) {
        if (!item || type !== 'row') return
        if (item !== null && item.compareSimilarity === null) {
          return 'table-warning'
        } else if (item !== null && item.temperature >= 37.3) {
          return 'table-danger'
        }
      },
      initData () {
        const t = this
        return {
          params () {
            return new Promise((resolve) => {
              t.pagination.order = t.$route.query.order !== undefined ? t.$route.query.order : t.dataOrderField
              t.pagination.ascending = parseInt(t.$route.query.ascending !== undefined ? t.$route.query.ascending : t.dataOrderAsc)
              resolve('done')
            })
          }
        }
      },
      doAutoRefresh () {
        this.ref = setInterval(() => {
          this.getDataTable()
        }, window.AppConfig.IntervalRefreshData)
      },
      showModalDetailOrder (data) {
        this.dataDetailOrder = data
        this.$bvModal.show('bv-modal-order-detail')
      },
      setData () {
        const t = this
        return {
          params (replaceOnly = false, addititonalData = []) {
            let arrayParams = {}
            arrayParams['limit'] = t.dataLimit
            // arrayParams['order'] = t.dataOrderField
            arrayParams['page'] = t.pagination.currentPage
            // arrayParams['order'] = t.pagination.order
            // arrayParams['ascending'] = t.pagination.ascending
            t.dataParams.forEach((key) => {
              if (addititonalData[key] !== undefined) {
                if (addititonalData[key] !== '' && addititonalData[key] !== null) {
                  arrayParams[key] = addititonalData[key]
                }
              } else if (t.$route.query[key] !== undefined) {
                arrayParams[key] = t.$route.query[key]
              } else if (t.defaultDataParams[key] !== undefined) {
                arrayParams[key] = t.defaultDataParams[key]
              }
            })
            t.$router.replace({path: t.$route.path, query: arrayParams}).catch(error => { if (error.name != "NavigationDuplicated") {throw error;} })
            if (!replaceOnly) {
              let stringParams = ''
              Object.keys(arrayParams).forEach((key) => {
                stringParams += (stringParams === '' ? '?' : '&') + key + '=' + arrayParams[key]
              })
              return stringParams
            }
          }
        }
      },
      initTableColumns () {
        this.tableColumns = []
        for (let i = 0; i < this.dataColumns.length; i++) {
          this.tableColumns.push({
            prop: this.dataColumns[i].key,
            label: this.dataColumns[i].label === undefined || this.dataColumns[i].label === null ? this.dataColumns[i].key.charAt(0).toUpperCase() + this.dataColumns[i].key.slice(1) : this.dataColumns[i].label,
            minWidth: this.dataColumns[i].minWidth === undefined ? null : this.dataColumns[i].minWidth,
            sortable: this.dataColumns[i].sortable === undefined ? null : this.dataColumns[i].sortable,
            isToggle: this.dataColumns[i].isToggle === undefined ? null : this.dataColumns[i].isToggle,
            formatThisDate: this.dataColumns[i].formatThisDate === undefined ? false : this.dataColumns[i].formatThisDate,
            isNumeric: this.dataColumns[i].isNumeric === undefined ? false : this.dataColumns[i].isNumeric,
            hasCustomSlot: this.hasSlot('content_' + this.dataColumns[i].key),
            align: this.dataColumns[i].align === undefined ? 'left' : this.dataColumns[i].align,
            needCurrency: this.dataColumns[i].needCurrency === undefined ? false : this.dataColumns[i].needCurrency,
            sort: this.dataColumns[i].sort === undefined ? true : this.dataColumns[i].sort
          })
        }
      },
      getDataTable (params = []) {
        this.isLoading = true
        this.failedToGetData = false
        this.$http.get(this.dataUrl + (this.dataUrl.indexOf('?') !== -1 ? '&' + this.setData().params(false, params).substr(1) : this.setData().params(false, params))).then(response => {
          this.tableData = response.data.data
          this.pagination.total = response.data.meta.total
          this.isLoading = false
          // this.pagination.currentPage = response.data.page
        }).catch(() => {
          this.isLoading = false
          this.failedToGetData = true
          // this.$SmoothScroll(this.$el.querySelector('#dataTable'))
        })
      },
      generateTemplateSlot () {
        this.customSlots = []
        this.dataColumns.forEach(fieldColumn => {
          if (this.hasSlot('content_' + fieldColumn.key)) {
            this.customSlots.push(fieldColumn)
          }
        })
      },
      setPage (page) {
        this.pagination.currentPage = page
      },
      onChangePage () {
        this.getDataTable()
      },
      doFilter (params) {
        this.setPage(1)
        this.setData().params(true, params)
        this.getDataTable(params)
      },
      doDeleteData (id) {
        this.$swal({
          title: 'Anda Yakin?',
          text: 'Data tidak akan dapat dikembalikan lagi!',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ya, Sudah',
          cancelButtonText: 'Tidak, belum',
          confirmButtonClass: 'btn btn-sm btn-danger mr-3',
          cancelButtonClass: 'btn btn-sm btn-secondary',
          buttonsStyling: false
        }).then(result => {
          if (result.value) {
            this.$http.delete((this.deleteUrl !== null ? this.deleteUrl : this.dataUrl) + '/' + id).then(() => {
              this.$store.dispatch('notification/success', 'Data berhasil dihapus.')
              this.getDataTable()
              this.$emit('afterDeleteData', id)
            }).catch(error => {
              this.$store.dispatch('notification/error', 'Data gagal dihapus.')
              // window.consoleLog(error)
            })
          }
        })
      },
      hasSlot (name = 'default') {
        return !!this.$slots[name] || !!this.$scopedSlots[name]
      },
      getDateTime (value) {
        return this.$moment(value).format('DD-MM-YYYY HH:mm')
      },
      onTableLoading () {
        this.$refs.progress.activateProgressBar()
        this.$store.dispatch('account/setIsOffline', false)
      },
      onTableLoaded () {
        this.$refs.progress.deactivateProgressBar()
        if (this.method_result !== undefined) {
          this.method_result()
        }
      },
      orderAction (row) {
        this.$emit('orderaction', row)
      }
    },
    beforeDestroy () {
      clearInterval(this.ref)
    },
    mounted () {
      this.generateTemplateSlot()
      this.initData().params().then(() => {
        // this.initTableColumns()
        this.getDataTable()
      })
    }
  };
</script>