<template>
  <div>
  <b-breadcrumb v-if="model.id !== null" :items="[{
        text: 'Daftar Akun Bank',
        href: '#/bank-accounts'
    }, {
        text: 'Detail Akun Bank',
        href: '#/bank-accounts/' + model.id
    }, {
        text: 'Ubah Akun Bank',
        active: true
  }]"></b-breadcrumb>
  <b-breadcrumb v-else :items="[{
    text: 'Daftar Akun Bank',
    href: '/#/bank-accounts'
  }, {
    text: 'Tambah Akun Bank',
    active: true
  }]"></b-breadcrumb>
  <b-card :title="(model.id !== null ? 'Edit' : 'Tambah') + ' Akun Bank'" style="max-width: 500px;margin: auto;">
      <b-form @submit.prevent="onSubmit">
        <b-form-group id="input-group-1" label="Nama*:" label-for="input-1">
            <b-form-input
            id="input-1"
            v-model="model.bank_name"
            placeholder="Ketikkan nama bank"
            required
            ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-2" label="No. Rek*:" label-for="input-2">
            <b-form-input
            id="input-2"
            v-model="model.bank_account_number"
            placeholder="Ketikkan no. rek"
            required
            ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-3" label="Atas Nama*:" label-for="input-3">
            <b-form-input
            id="input-3"
            v-model="model.bank_account_person"
            placeholder="Ketikkan atas nama"
            required
            ></b-form-input>
        </b-form-group>
      <b-button type="submit" variant="primary" :disabled="isLoading"><font-awesome-icon v-if="isLoading"  icon="spinner" spin /> Simpan</b-button>
    </b-form>
  </b-card>
  </div>
</template>
<script>
    export default {
        data () {
            return {
                isLoading: false,
                apiUrl: {
                    bankAccount: process.env.VUE_APP_API_URL + 'bank-accounts'
                },
                model: {
                    id: null,
                    bank_name: null,
                    bank_account_number: null,
                    bank_account_person: null
                }
            }
        },
        watch: {
            '$route.params.id': function (val) {
                if (val !== undefined) {
                    this.getDetail()
                } else {
                    this.resetForm()
                }
            }
        },
        mounted () {
            if (this.$route.params.id !== undefined) {
                this.getDetail()
            }
        },
        methods: {
            resetForm () {
                this.model.id = null
                this.model.bank_name = null
                this.model.bank_account_number = null
                this.model.bank_account_person = null
            },
            onSubmit () {
                this.isLoading = true
                const formData = new FormData()
                if (this.model.id !== null && this.model.id !== '') {
                    formData.append('_method', 'PATCH');
                }
                if (this.model.bank_name !== null && this.model.bank_name !== '') {
                    formData.append('bank_name', this.model.bank_name);
                }
                if (this.model.bank_account_number !== null && this.model.bank_account_number !== '') {
                    formData.append('bank_account_number', this.model.bank_account_number);
                }
                if (this.model.bank_account_person !== null && this.model.bank_account_person !== '') {
                    formData.append('bank_account_person', this.model.bank_account_person);
                }
                this.$http.post(this.apiUrl.bankAccount + (this.model.id !== null && this.model.id !== '' ? '/' + this.model.id : ''), formData).then(response => {
                    this.isLoading = false
                    if (this.model.id !== null && this.model.id !== '') {
                        this.$store.dispatch('notification/success', 'Akun Bank berhasil diubah.')
                        this.$router.push('/bank-accounts/' + response.data.id)
                    } else {
                        this.$store.dispatch('notification/success', 'Akun Bank berhasil disimpan.')
                        this.$router.push('/bank-accounts')
                    }
                }).catch(error => {
                    this.isLoading = false
                    this.$store.dispatch('notification/error', error)
                })
            },
            getDetail () {
                this.isLoading = true
                this.$http.get(this.apiUrl.bankAccount + '/' + this.$route.params.id)
                .then(response => {
                    this.model.id = response.data.id
                    this.model.bank_name = response.data.bank_name
                    this.model.bank_account_number = response.data.bank_account_number
                    this.model.bank_account_person = response.data.bank_account_person
                    this.isLoading = false
                }).catch(error => {
                    this.isLoading = false
                    this.$store.dispatch('notification/error', error)
                    if (typeof error !== 'undefined' && typeof error.response !== 'undefined' && typeof error.response.status !== 'undefined' && error.response.status === 404) {
                        this.$router.push('/notfound')
                    }
                })
            }
        }
    }
</script>