<template>
    <div>
    <b-breadcrumb :items="[{
        text: 'Daftar Order',
        href: '#/orders'
    }, {
        text: 'Detail Order',
        active: true
    }]"></b-breadcrumb>
    <b-card>
        <b-row>
            <b-col md="12">
                <b-row>
                    <b-col md="6">
                        <h3 class="page-title mb-0"> #{{model.order_no}}</h3>
                    </b-col>
                    <b-col md="6" class="text-right" v-if="model.status !== 3 && model.status !== 0">
                        <template v-if="model.status === 1">
                            <b-button @click="openBillingModal" type="button" size="md" variant="warning" class="mr-3" :disabled="isLoading.payment"><font-awesome-icon v-if="isLoading.payment" icon="spinner" spin />Buat Penagihan</b-button>
                        </template>
                        <template v-else>
                            <b-button @click="doUpdateStatus(3)" type="button" size="md" variant="success" class="mr-3" :disabled="isLoading.status">Approve</b-button>
                        </template>
                        <!-- <b-button @click="checkPaymentStatus" type="button" size="md" variant="success" class="mr-3" :disabled="isLoading.payment"><font-awesome-icon v-if="isLoading.payment" icon="spinner" spin /> Cek Pembayaran</b-button> -->
                        <b-button @click="doUpdateStatus(0)" type="button" size="md" variant="danger" :disabled="isLoading.status">Batal</b-button>
                    </b-col>
                    <b-col class="text-right" md="6" v-else>
                        <b-button type="button" size="md" target="_blank" :href="'#/orders/' + $route.params.id + '/invoice'" variant="success" class="mr-3" :disabled="isLoading.payment"><font-awesome-icon icon="print" /> Incoive</b-button>
                    </b-col>
                </b-row>
            </b-col>
            <b-col md="6">
                <b-row class="mt-3">
                    <b-col md="3">
                        <strong>Tanggal</strong>
                    </b-col>
                    <b-col md="9">
                        {{$moment(model.created_at).format('DD-MM-YYYY HH:mm')}}
                    </b-col>
                </b-row>
            </b-col>
            <b-col md="6">
                <b-row class="mt-3">
                    <b-col md="3">
                        <strong>Jatuh Tempo</strong>
                    </b-col>
                    <b-col md="9">
                        {{$moment(model.due_datetime).format('DD-MM-YYYY HH:mm')}}
                    </b-col>
                </b-row>
            </b-col>
            <b-col md="6">
                <b-row class="mt-3">
                    <b-col md="3">
                        <strong>Status</strong>
                    </b-col>
                    <b-col md="9">
                        <div style="text-align: left;">
                            <b-badge v-if="model.status === 0" variant="danger" class="p-2" style="font-weight: normal" size="sm">
                            Batal
                            </b-badge>
                            <b-badge v-else-if="model.status === 1" variant="secondary" class="p-2" style="font-weight: normal" size="sm">
                            Menunggu
                            </b-badge>
                            <b-badge v-else-if="model.status === 2" variant="warning" class="p-2" style="font-weight: normal" size="sm">
                            Ditagih
                            </b-badge>
                            <b-badge v-else-if="model.status === 3" variant="success" class="p-2" style="font-weight: normal" size="sm">
                            Selesai
                            </b-badge>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="mt-3">
                    <b-col md="3">
                        <strong>Tipe</strong>
                    </b-col>
                    <b-col md="9">
                        <div style="text-align: left;">{{model.type !== null ? model.type : '-'}}</div>
                    </b-col>
                </b-row>
            </b-col>
            <b-col md="6" v-if="model.buyer_note !== null">
                <b-row class="mt-3">
                    <b-col md="3">
                        <strong>Catatan Pembeli</strong>
                    </b-col>
                    <b-col md="9">
                        {{model.buyer_note}}
                    </b-col>
                </b-row>
            </b-col>
            <b-col md="6" v-if="model.unique_number !== null">
                <b-row class="mt-3">
                    <b-col md="3">
                        <strong>Kode Unik</strong>
                    </b-col>
                    <b-col md="9" class="text-left">
                        {{model.unique_number}}
                    </b-col>
                </b-row>
            </b-col>
            <template v-if="model.user !== null">
                <b-col md="12" class="mt-3">
                    <h4>Data Pembeli</h4>
                </b-col>
                <b-col md="6" class="mb-3">
                    <b-row class="mt-3">
                        <b-col md="3">
                            <strong>Nama</strong>
                        </b-col>
                        <b-col md="9">
                            {{model.user.name}}
                        </b-col>
                    </b-row>
                    <b-row class="mt-3" v-if="model.user.whatsapp_number">
                        <b-col md="3">
                            <strong>No. Whatsapp</strong>
                        </b-col>
                        <b-col md="9">
                            {{model.user.whatsapp_number}}
                        </b-col>
                    </b-row>
                    <b-row class="mt-3" v-if="model.user.address">
                        <b-col md="3">
                            <strong>Alamat</strong>
                        </b-col>
                        <b-col md="9">
                            {{model.user.address}}
                        </b-col>
                    </b-row>
                    <b-row class="mt-3" v-if="model.user.email">
                        <b-col md="3">
                            <strong>Email</strong>
                        </b-col>
                        <b-col md="9">
                            {{model.user.email}}
                        </b-col>
                    </b-row>
                    <b-row class="mt-3" v-if="model.user.url_fb">
                        <b-col md="3">
                            <strong>Url Facebook</strong>
                        </b-col>
                        <b-col md="9">
                            <a :href="model.user.url_fb">{{model.user.url_fb}}</a>
                        </b-col>
                    </b-row>
                </b-col>
            </template>
            <b-col md="12" class="mt-3">
                <h5>Rincian Pesanan</h5>
                <table class="table table-striped mt-1 mb-0">
                    <thead>
                        <tr>
                            <th width="40%" class="text-left">Kode</th>
                            <th width="40%" class="text-left">Nama Produk</th>
                            <th width="10%" class="text-center">Qty</th>
                            <th width="25%" class="text-right">Harga</th>
                            <th width="25%" class="text-right">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-if="model.details.length > 0">
                            <tr v-for="detail in model.details" :key="detail.id">
                                <td>{{detail.product !== null ? detail.product.sku_id : '-'}}</td>
                                <td>{{detail.product !== null ? detail.product.name : '-'}}</td>
                                <td class="text-center">{{detail.quantity}}</td>
                                <td class="text-right">{{$formatPrice(detail.price)}}</td>
                                <td class="text-right">{{$formatPrice(detail.total)}}</td>
                            </tr>
                        </template>
                        <tr v-else>
                            <td colspan="5">Tidak data untuk saat ini</td>
                        </tr>
                        <tr>
                            <td colspan="4"><strong>Sub Total</strong></td>
                            <td class="text-right">{{$formatPrice(model.sub_total)}}</td>
                        </tr>
                        <tr>
                            <td colspan="4"><strong>Diskon</strong></td>
                            <td class="text-right">{{$formatPrice(model.discount)}}</td>
                        </tr>
                        <tr>
                            <td colspan="4"><strong>Ongkos Kirim</strong></td>
                            <td class="text-right">{{$formatPrice(model.shipping_fee)}}</td>
                        </tr>
                        <tr>
                            <td colspan="4"><strong>Total</strong></td>
                            <td class="text-right">{{$formatPrice(model.total)}}</td>
                        </tr>
                    </tbody>
                </table>
            </b-col>
        </b-row>
    </b-card>
    <b-modal id="modal-order-billing" size="md" title="Buat Penagihan">
        <template #modal-footer>
            <b-button type="button" variant="secondary" @click.prevent="$bvModal.hide('modal-order-billing')">Batal</b-button>
            <b-button type="button" variant="primary" @click.prevent="doSubmitCustomer" :disabled="isLoading.status"><font-awesome-icon v-if="isLoading.status"  icon="spinner" spin /> Simpan</b-button>
        </template>
        <form ref="order-billing">
            <template v-if="model.user === null">
                <b-form-group label="Gunakan data customer:">
                    <b-form-radio-group
                        id="radio-group-1"
                        v-model="modalModel.customerType"
                        :options="options.customerTypes"
                        name="radio-options"
                    ></b-form-radio-group>
                </b-form-group>
                <template v-if="modalModel.customerType === 'old'">
                    <autocomplete :search="searchCustomer" placeholder="Cari data customer" aria-label="Cari data customer" :get-result-value="getResultValue" @submit="handleSubmit" class="mb-3"></autocomplete>
                </template>
                <template v-else>
                    <b-form-group id="input-group-1" label-for="input-1">
                        <label>Name<sup class="text-danger">*</sup>:</label>
                        <b-form-input
                        id="input-1"
                        v-model="modalModel.name"
                        placeholder="Ketikkan nama"
                        required
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group id="input-group-1" label-for="input-1">
                        <label>No. Whatsapp<sup class="text-danger">*</sup>:</label>
                        <b-form-input
                        id="input-1"
                        v-model="modalModel.whatsapp_number"
                        placeholder="Ketikkan nomor whatsapp"
                        required
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group id="input-group-1" label="Alamat:" label-for="input-1">
                        <b-form-textarea
                        id="input-1"
                        v-model="modalModel.address"
                        placeholder="Ketikkan alamat"
                        required
                        ></b-form-textarea>
                    </b-form-group>
                    <b-form-group id="input-group-1" label="Email:" label-for="input-1">
                        <b-form-input
                        id="input-1"
                        v-model="modalModel.email"
                        placeholder="Ketikkan email"
                        required
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group id="input-group-1" label="URL Profile Facebook:" label-for="input-1">
                        <b-form-input
                        id="input-1"
                        v-model="modalModel.url_fb"
                        placeholder="Ketikkan url profile facebook"
                        required
                        ></b-form-input>
                    </b-form-group>
                </template>
            </template>
            <b-form-group id="input-group-1" label-for="input-1">
                <label>Jatuh Tempo<sup class="text-danger"></sup>:</label>
                <b-input-group-append>
                <b-form-input
                    style="width: 300px; text-align: left;"
                    v-model="modalModel.due_datetime"
                    type="text"
                    placeholder="Pilih tanggal jatuh tempo"
                    autocomplete="off"
                ></b-form-input>
                <b-form-datepicker
                    v-model="modalModel.due_datetime"
                    button-only
                    right
                    locale="en-US"
                ></b-form-datepicker>
                </b-input-group-append>
            </b-form-group>
            <b-form-group id="input-group-1" label-for="input-1">
                <label>Ongkos kirim<sup class="text-danger"></sup>:</label>
                <b-form-input
                type="number"
                id="input-1"
                v-model="modalModel.shipping_fee"
                placeholder="Ketikkan ongkos kirim"
                required
                ></b-form-input>
            </b-form-group>
            <b-form-group id="input-group-1" label-for="input-1">
                <label>Diskon<sup class="text-danger"></sup>:</label>
                <b-form-input
                id="input-1"
                v-model="modalModel.discount"
                placeholder="Ketikkan diskon dalam satuan rupiah"
                required
                ></b-form-input>
            </b-form-group>
        </form>
    </b-modal>
    </div>
</template>
<script>
    import Vue from 'vue'
    import Autocomplete from '@trevoreyre/autocomplete-vue'
    import '@trevoreyre/autocomplete-vue/dist/style.css'
    import VueHtmlToPaper from 'vue-html-to-paper'

    const options = {
        name: '_blank',
        specs: [
            'fullscreen=yes',
            'titlebar=yes',
            'scrollbars=yes'
        ],
        styles: [
            'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
            'https://unpkg.com/kidlat-css/css/kidlat.css'
        ],
        timeout: 1000, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: window.document.title, // override the window title
    }
    Vue.use(VueHtmlToPaper, options)
    Vue.use(Autocomplete)
    export default {
        data () {
            return {
                isLoading: {
                    order: true,
                    payment: false,
                    status: false
                },
                apiUrl: {
                    order: process.env.VUE_APP_API_URL + 'orders',
                    customer: process.env.VUE_APP_API_URL + 'users'
                },
                model: {
                    id: null,
                    buyer_note: null,
                    created_at: null,
                    details: [],
                    discount: null,
                    due_datetime: null,
                    order_no: null,
                    ref_id: null,
                    status: null,
                    sub_total: null,
                    total: null,
                    unique_number: null,
                    user: null
                },
                options: {
                    customerTypes: [
                        { text: 'Yang sudah ada', value: 'old' },
                        { text: 'Customer Baru', value: 'new' }
                    ]
                },
                modalModel: {
                    shipping_fee: null,
                    due_datetime: null,
                    discount: null,
                    customerType: 'old',
                    name: null,
                    email: null,
                    whatsapp_number: null,
                    url_fb: null,
                    address: null,
                    user_id: null
                }
            }
        },
        watch: {
            '$route.params.id': function (val) {
                if (val !== undefined) {
                    this.getDetail()
                }
            }
        },
        mounted () {
            if (this.$route.params.id !== undefined) {
                this.getDetail()
            }
        },
        methods: {
            getDetail () {
                this.isLoading.order = false
                this.$http.get(this.apiUrl.order + '/' + this.$route.params.id + '?payment_confirmation=1')
                .then(response => {
                    this.model.id = response.data.id
                    this.model.buyer_note = response.data.buyer_note
                    this.model.type = response.data.type
                    this.model.created_at = response.data.created_at
                    this.model.details = response.data.details
                    this.model.discount = response.data.discount
                    this.model.due_datetime = response.data.due_datetime
                    this.model.order_no = response.data.order_no
                    this.model.ref_id = response.data.ref_id
                    this.model.status = response.data.status
                    this.model.sub_total = response.data.sub_total
                    this.model.shipping_fee = response.data.shipping_fee
                    this.model.total = response.data.total
                    this.model.unique_number = response.data.unique_number
                    this.model.user = response.data.user
                    this.isLoading.order = false
                }).catch(error => {
                    this.isLoading.order = false
                    this.$store.dispatch('notification/error', error)
                    if (typeof error !== 'undefined' && typeof error.response !== 'undefined' && typeof error.response.status !== 'undefined' && error.response.status === 404) {
                        this.$router.push('/notfound')
                    }
                })
            },
            doSubmitCustomer () {
                this.isLoading.status = true
                if (this.modalModel.customerType === 'old' || this.model.user !== null) {
                    this.$http.patch(this.apiUrl.order + '/' + this.$route.params.id + '/user', {
                        user_id: this.model.user !== null ? this.model.user.id : this.modalModel.user_id,
                        discount: this.modalModel.discount,
                        due_datetime: this.modalModel.due_datetime,
                        shipping_fee: this.modalModel.shipping_fee
                    }).then(response => {
                        this.isLoading.status = false
                        this.$bvModal.hide('modal-order-billing')
                        this.getDetail()
                    }).catch(error => {
                        this.isLoading.status = false
                        this.$store.dispatch('notification/error', error)
                    })
                } else {
                    console.log({
                        name: this.modalModel.name,
                        email: this.modalModel.email,
                        role: 'customer',
                        whatsapp_number: this.modalModel.whatsapp_number,
                        url_fb: this.modalModel.url_fb,
                        address: this.modalModel.address,
                        order_id: this.$route.params.id,
                        discount: this.modalModel.discount,
                        due_datetime: this.modalModel.due_datetime,
                        shipping_fee: this.modalModel.shipping_fee
                    })
                    this.$http.post(this.apiUrl.customer, {
                        name: this.modalModel.name,
                        email: this.modalModel.email,
                        role: 'customer',
                        whatsapp_number: this.modalModel.whatsapp_number,
                        url_fb: this.modalModel.url_fb,
                        address: this.modalModel.address,
                        order_id: this.$route.params.id,
                        discount: this.modalModel.discount,
                        due_datetime: this.modalModel.due_datetime,
                        shipping_fee: this.modalModel.shipping_fee
                    }).then(response => {
                        this.isLoading.status = false
                        this.$bvModal.hide('modal-order-billing')
                        this.getDetail()
                    }).catch(error => {
                        this.isLoading.status = false
                        this.$store.dispatch('notification/error', error)
                    })
                }
            },
            doUpdateStatus (value) {
                this.isLoading.status = true
                this.$http.patch(this.apiUrl.order + '/' + this.$route.params.id + '/status', {
                    status: value
                }).then(response => {
                    this.getDetail()
                    this.isLoading.status = false
                }).catch(error => {
                    this.isLoading = false
                    this.$store.dispatch('notification/error', error)
                })
            },
            openBillingModal () {
                this.$bvModal.show('modal-order-billing')
            },
            searchCustomer(value) {
                return new Promise(resolve => {
                    if (value.length < 3) {
                        return resolve([])
                    }

                    this.$http.get(this.apiUrl.customer + '?role=customer&name=' + value)
                    .then(response => {
                        resolve(response.data.data)
                    })
                })
            },
            getResultValue(result) {
                return result.name
            },
            handleSubmit(result) {
                this.modalModel.user_id = result.id
            },
            print () {
                this.$htmlToPaper('printMe');
            }
        }
    }
</script>