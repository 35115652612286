<template>
  <div>
  <b-breadcrumb v-if="model.id !== null" :items="[{
        text: 'Daftar Customer',
        href: '#/customers'
    }, {
        text: 'Detail Customers',
        href: '#/customers/' + model.id
    }, {
        text: 'Ubah Customer',
        active: true
  }]"></b-breadcrumb>
  <b-breadcrumb v-else :items="[{
    text: 'Daftar Customer',
    href: '/#/customers'
  }, {
    text: 'Tambah Customer',
    active: true
  }]"></b-breadcrumb>
  <b-card :title="(model.id !== null ? 'Edit' : 'Tambah') + (model.parent !== null ? ' Sub' : '')+ ' Customer'" style="max-width: 500px;margin: auto;">
      <b-form @submit.prevent="onSubmit">
        <b-form-group id="input-group-1" label="Nama*:" label-for="input-1">
            <b-form-input
            id="input-1"
            v-model="model.name"
            placeholder="Ketikkan nama"
            required
            ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-1" label="No. Whatsapp*:" label-for="input-1">
            <b-form-input
            id="input-1"
            type="tel"
            v-model="model.whatsapp_number"
            @change="validateWANumber"
            placeholder="Ketikkan nomor whatsapp"
            required
            ></b-form-input>
            <!-- <small><b>format penginputan nomor whatsapp: 6281234465678</b></small> -->
        </b-form-group>
        <b-form-group id="input-group-1" label="Alamat:" label-for="input-1">
            <b-form-textarea
            id="input-1"
            v-model="model.address"
            placeholder="Ketikkan alamat"
            ></b-form-textarea>
        </b-form-group>
        <b-form-group id="input-group-1" label="Email:" label-for="input-1">
            <b-form-input
            id="input-1"
            v-model="model.email"
            placeholder="Ketikkan email"
            ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-1" label="URL Profile Facebook:" label-for="input-1">
            <b-form-input
            id="input-1"
            v-model="model.url_fb"
            placeholder="Ketikkan url profile facebook"
            ></b-form-input>
        </b-form-group>
      <b-button type="submit" variant="primary" :disabled="isLoading"><font-awesome-icon v-if="isLoading"  icon="spinner" spin /> Simpan</b-button>
    </b-form>
  </b-card>
  </div>
</template>
<script>
    // import QuillEditor from '@/components/QuillEditor'
    import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js'

    export default {
        // components: {
        //   QuillEditor
        // },
        data () {
            return {
                isLoading: false,
                apiUrl: {
                    customer: process.env.VUE_APP_API_URL + 'users'
                },
                model: {
                    id: null,
                    whatsapp_number: null,
                    email: null,
                    name: null,
                    url_fb: null,
                    address: null,
                }
            }
        },
        watch: {
            '$route.params.id': function (val) {
                if (val !== undefined) {
                    this.getDetail()
                } else {
                    this.resetForm()
                }
            }
        },
        mounted () {
            if (this.$route.params.id !== undefined) {
                this.getDetail()
            }
        },
        methods: {
            validateWANumber () {
                if (this.model.whatsapp_number !== null && this.model.whatsapp_number !== '') {
                    if (isValidPhoneNumber(this.model.whatsapp_number.trim(), 'ID') === false) {
                        this.$store.dispatch('notification/error', 'No. WA tidak valid')
                    } else {
                        this.model.whatsapp_number = parsePhoneNumber(this.model.whatsapp_number.trim(), 'ID').number
                    }
                }
            },
            resetForm () {
                this.model.id = null
                this.model.name = null
                this.model.whatsapp_number = null
                this.model.email = null
                this.model.url_fb = null
                this.model.address = null
            },
            onSubmit () {
                if (isValidPhoneNumber(this.model.whatsapp_number.trim(), 'ID') === false) {
                    this.$store.dispatch('notification/error', 'No. WA tidak valid')
                    return false;
                }
                this.isLoading = true
                const formData = new FormData()
                if (this.model.id !== null && this.model.id !== '') {
                    formData.append('_method', 'PATCH');
                }
                if (this.model.name !== null && this.model.name !== '') {
                    formData.append('name', this.model.name);
                }
                if (this.model.whatsapp_number !== null && this.model.whatsapp_number !== '') {
                    formData.append('whatsapp_number', this.model.whatsapp_number);
                }
                if (this.model.email !== null && this.model.email !== '') {
                    formData.append('email', this.model.email);
                }
                if (this.model.url_fb !== null && this.model.url_fb !== '') {
                    formData.append('url_fb', this.model.url_fb);
                }
                if (this.model.address !== null && this.model.address !== '') {
                    formData.append('address', this.model.address);
                }
                formData.append('role', 'customer');
                this.$http.post(this.apiUrl.customer + (this.model.id !== null && this.model.id !== '' ? '/' + this.model.id : ''), formData).then(response => {
                    this.isLoading = false
                    if (this.model.id !== null && this.model.id !== '') {
                        this.$store.dispatch('notification/success', 'Customer berhasil diubah.')
                    } else {
                        this.$store.dispatch('notification/success', 'Customer berhasil disimpan.')
                    }
                    this.$router.push('/customers/' + response.data.id)
                }).catch(error => {
                    this.isLoading = false
                    this.$store.dispatch('notification/error', error)
                })
            },
            getDetail () {
                this.isLoading = true
                this.$http.get(this.apiUrl.customer + '/' + this.$route.params.id)
                .then(response => {
                    this.model.id = response.data.id
                    this.model.name = response.data.name
                    this.model.email = response.data.email
                    this.model.whatsapp_number = response.data.whatsapp_number
                    this.model.url_fb = response.data.url_fb
                    this.model.address = response.data.address
                    this.isLoading = false
                }).catch(error => {
                    this.isLoading = false
                    this.$store.dispatch('notification/error', error)
                    if (typeof error !== 'undefined' && typeof error.response !== 'undefined' && typeof error.response.status !== 'undefined' && error.response.status === 404) {
                        this.$router.push('/notfound')
                    }
                })
            },
            onEditorChange (val) {
                this.model.description = val === '<p><br></p>' ? null : val
            }
        }
    }
</script>