<template>
  <div>
  <b-breadcrumb v-if="model.id !== null" :items="[{
        text: 'Daftar Kategori Produk',
        href: '#/product-categories'
    }, {
        text: 'Detail Kategori Produk',
        href: '#/product-categories/' + model.id
    }, {
        text: 'Ubah Kategori Produk',
        active: true
  }]"></b-breadcrumb>
  <b-breadcrumb v-else :items="[{
    text: 'Daftar Kategori Produk',
    href: '/#/product-categories'
  }, {
    text: 'Tambah Kategori Produk',
    active: true
  }]"></b-breadcrumb>
  <b-card :title="(model.id !== null ? 'Edit' : 'Tambah') + ' Kategori Produk'" style="max-width: 500px;margin: auto;">
      <b-form @submit.prevent="onSubmit">
        <b-form-group id="input-group-1" label="Nama*:" label-for="input-1">
            <b-form-input
            id="input-1"
            v-model="model.name"
            placeholder="Ketikkan nama"
            required
            ></b-form-input>
        </b-form-group>
        <b-form-group v-if="model.id !== null" id="input-group-3" label="Urutan*:" label-for="input-3">
            <b-form-input
            id="input-3"
            v-model="model.sort"
            placeholder="Ketikkan urutan"
            required
            ></b-form-input>
        </b-form-group>
      <b-button type="submit" variant="primary" :disabled="isLoading"><font-awesome-icon v-if="isLoading"  icon="spinner" spin /> Simpan</b-button>
    </b-form>
  </b-card>
  </div>
</template>
<script>
    import QuillEditor from '@/components/QuillEditor'

    export default {
        components: {
          QuillEditor
        },
        data () {
            return {
                isLoading: false,
                apiUrl: {
                    productCategory: process.env.VUE_APP_API_URL + 'product-categories'
                },
                model: {
                    id: null,
                    name: null,
                    sort: null
                    // logo: null
                }
            }
        },
        watch: {
            '$route.params.id': function (val) {
                if (val !== undefined) {
                    this.getDetail()
                } else {
                    this.resetForm()
                }
            }
        },
        mounted () {
            if (this.$route.params.id !== undefined) {
                this.getDetail()
            }
        },
        methods: {
            resetForm () {
                this.model.id = null
                this.model.name = null
                this.model.sort = null
            },
            onSubmit () {
                this.isLoading = true
                const formData = new FormData()
                if (this.model.id !== null && this.model.id !== '') {
                    formData.append('_method', 'PATCH');
                }
                if (this.model.name !== null && this.model.name !== '') {
                    formData.append('name', this.model.name);
                }
                if (this.model.sort !== null && this.model.sort !== '') {
                    formData.append('sort', this.model.sort);
                }
                this.$http.post(this.apiUrl.productCategory + (this.model.id !== null && this.model.id !== '' ? '/' + this.model.id : ''), formData).then(response => {
                    this.isLoading = false
                    if (this.model.id !== null && this.model.id !== '') {
                        this.$store.dispatch('notification/success', 'Kategori berhasil diubah.')
                    } else {
                        this.$store.dispatch('notification/success', 'Kategori berhasil disimpan.')
                    }
                    this.$router.push('/product-categories/' + response.data.id)
                }).catch(error => {
                    this.isLoading = false
                    this.$store.dispatch('notification/error', error)
                })
            },
            getDetail () {
                this.isLoading = true
                this.$http.get(this.apiUrl.productCategory + '/' + this.$route.params.id)
                .then(response => {
                    this.model.id = response.data.id
                    this.model.name = response.data.name
                    this.model.sort = response.data.sort
                    this.isLoading = false
                }).catch(error => {
                    this.isLoading = false
                    this.$store.dispatch('notification/error', error)
                    if (typeof error !== 'undefined' && typeof error.response !== 'undefined' && typeof error.response.status !== 'undefined' && error.response.status === 404) {
                        this.$router.push('/notfound')
                    }
                })
            },
            onEditorChange (val) {
                this.model.description = val === '<p><br></p>' ? null : val
            }
        }
    }
</script>