<template>
  <div>
  <b-breadcrumb v-if="model.id !== null" :items="[{
        text: 'Daftar Produk',
        href: '#/products'
    }, {
        text: 'Detail Produk',
        href: '#/products/' + model.id
    }, {
        text: 'Ubah Produk',
        active: true
  }]"></b-breadcrumb>
  <b-breadcrumb v-else :items="[{
    text: 'Daftar Produk',
    href: '/#/products'
  }, {
    text: 'Tambah Produk',
    active: true
  }]"></b-breadcrumb>
  <b-card :title="(model.id !== null ? 'Edit' : 'Tambah') + ' Produk'" style="max-width: 500px;margin: auto;">
      <b-form @submit.prevent="onSubmit">
        <b-form-group id="input-group-1" label="Nama*:" label-for="input-1">
            <b-form-input
            id="input-1"
            v-model="model.name"
            placeholder="Ketikkan nama"
            required
            ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-1" label="Kode Produk*:" label-for="input-1">
            <b-form-input
            id="input-1"
            v-model="model.sku_id"
            placeholder="Ketikkan kode produk"
            required
            ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-4" label="Kategori*:" label-for="input-4">
            <b-form-select id="input-4" required v-model="model.product_category_id" :options="options.productCategories">
                <template #first>
                    <b-form-select-option :value="null" disabled>-- Pilih Kategori --</b-form-select-option>
                </template>
            </b-form-select>
        </b-form-group>
        <b-form-group id="input-group-8" label="Harga Jual*:" label-for="input-8">
            <b-form-input
            type="number"
            id="input-8"
            v-model="model.selling_price"
            placeholder="Harga jual"
            required
            ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-8" label="Harga Beli*:" label-for="input-8">
            <b-form-input
            type="number"
            id="input-8"
            v-model="model.purchase_price"
            placeholder="Harga beli"
            required
            ></b-form-input>
        </b-form-group>
        <b-form-group v-if="this.model.id === null" id="input-group-8" label="Stok*:" label-for="input-8">
            <b-form-input
            type="number"
            id="input-8"
            v-model="model.quantity"
            placeholder="Stok"
            required
            ></b-form-input>
        </b-form-group>
      <b-button type="submit" variant="primary" :disabled="isLoading"><font-awesome-icon v-if="isLoading"  icon="spinner" spin /> Simpan</b-button>
    </b-form>
  </b-card>
  </div>
</template>
<script>
    // import QuillEditor from '@/components/QuillEditor'

    export default {
        // components: {
        //   QuillEditor
        // },
        data () {
            return {
                isLoading: false,
                apiUrl: {
                    product: process.env.VUE_APP_API_URL + 'products',
                    productCategory: process.env.VUE_APP_API_URL + 'product-categories'
                },
                model: {
                    id: null,
                    name: null,
                    product_category_id: null,
                    selling_price: null,
                    purchase_price: null,
                    quantity: null,
                    sku_id: null
                },
                options: {
                    productCategories: [],
                }
            }
        },
        watch: {
            '$route.params.id': function (val) {
                if (val !== undefined) {
                    this.getDetail()
                } else {
                    this.resetForm()
                }
            }
        },
        mounted () {
            this.getProductCategories()
            if (this.$route.params.id !== undefined) {
                this.getDetail()
            }
        },
        methods: {
            resetForm () {
                this.model.id = null
                this.model.name = null
                this.model.product_category_id = null
                this.model.selling_price = null
                this.model.purchase_price = null
                this.model.quantity = null
                this.model.sku_id = null
            },
            onSubmit () {
                this.isLoading = true
                const formData = new FormData()
                if (this.model.id !== null && this.model.id !== '') {
                    formData.append('_method', 'PATCH');
                }
                if (this.model.name !== null && this.model.name !== '') {
                    formData.append('name', this.model.name);
                }
                if (this.model.sku_id !== null && this.model.sku_id !== '') {
                    formData.append('sku_id', this.model.sku_id);
                }
                if (this.model.selling_price !== null && this.model.selling_price !== '') {
                    formData.append('selling_price', this.model.selling_price);
                }
                if (this.model.purchase_price !== null && this.model.purchase_price !== '') {
                    formData.append('purchase_price', this.model.purchase_price);
                }
                if (this.model.quantity !== null && this.model.quantity !== '') {
                    formData.append('quantity', this.model.quantity);
                }
                if (this.model.product_category_id !== null && this.model.product_category_id !== '') {
                    formData.append('product_category_id', this.model.product_category_id);
                }
                this.$http.post(this.apiUrl.product + (this.model.id !== null && this.model.id !== '' ? '/' + this.model.id : ''), formData).then(response => {
                    this.isLoading = false
                    if (this.model.id !== null && this.model.id !== '') {
                        this.$store.dispatch('notification/success', 'Produk berhasil diubah.')
                    } else {
                        this.$store.dispatch('notification/success', 'Produk berhasil disimpan.')
                    }
                    this.$router.push('/products/' + response.data.id)
                }).catch(error => {
                    this.isLoading = false
                    this.$store.dispatch('notification/error', error)
                })
            },
            getDetail () {
                this.isLoading = true
                this.$http.get(this.apiUrl.product + '/' + this.$route.params.id)
                .then(response => {
                    this.model.id = response.data.id
                    this.model.product_category_id = response.data.product_category_id
                    this.model.name = response.data.name
                    this.model.selling_price = response.data.selling_price
                    this.model.purchase_price = response.data.purchase_price
                    this.model.quantity = response.data.quantity
                    this.model.sku_id = response.data.sku_id
                    this.isLoading = false
                }).catch(error => {
                    this.isLoading = false
                    this.$store.dispatch('notification/error', error)
                    if (typeof error !== 'undefined' && typeof error.response !== 'undefined' && typeof error.response.status !== 'undefined' && error.response.status === 404) {
                        this.$router.push('/notfound')
                    }
                })
            },
            getProductCategories () {
                this.isLoading = true
                this.$http.get(this.apiUrl.productCategory)
                .then(response => {
                    this.options.productCategories = []
                    response.data.data.forEach(parent => {
                        this.options.productCategories.push({
                            value: parent.id,
                            text: parent.name
                        })
                    })
                    this.isLoading = false
                }).catch(error => {
                    this.isLoading = false
                    this.$store.dispatch('notification/error', error)
                })
            },
            // onEditorChange (val) {
            //     this.model.description = val === '<p><br></p>' ? null : val
            // }
        }
    }
</script>