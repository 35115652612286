<template>
    <div>
    <b-breadcrumb :items="[{
        text: 'Daftar Akun Bank',
        href: '#/bank-accounts'
    }, {
        text: 'Detail',
        active: true
    }]"></b-breadcrumb>
    <b-card>
        <b-row>
            <b-col md="12">
                <b-row>
                    <b-col md="6">
                        
                    </b-col>
                    <b-col md="6" class="text-right">
                        <b-button :href="'#/bank-accounts/'+$route.params.id+'/edit'" size="md" variant="secondary"><font-awesome-icon icon="edit" /> Edit</b-button>
                    </b-col>
                </b-row>
            </b-col>
            <b-col md="6">
                <b-row class="mt-3">
                    <b-col md="3">
                        <strong>Nama</strong>
                    </b-col>
                    <b-col md="9">
                        {{model.bank_name}}
                    </b-col>
                </b-row>
            </b-col>
            <b-col md="6">
                <b-row class="mt-3">
                    <b-col md="3">
                        <strong>No. Rek</strong>
                    </b-col>
                    <b-col md="9">
                        {{model.bank_account_number}}
                    </b-col>
                </b-row>
            </b-col>
            <b-col md="6">
                <b-row class="mt-3">
                    <b-col md="3">
                        <strong>Atas Nama</strong>
                    </b-col>
                    <b-col md="9">
                        {{model.bank_account_person}}
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-card>
    </div>
</template>
<script>
    export default {
        data () {
            return {
                isLoading: {
                    bankAccount: true
                },
                apiUrl: {
                    bankAccount: process.env.VUE_APP_API_URL + 'bank-accounts'
                },
                model: {
                    id: null,
                    bank_name: null,
                    bank_account_number: null,
                    bank_account_person: null
                }
            }
        },
        watch: {
            '$route.params.id': function (val) {
                if (val !== undefined) {
                    this.getDetail()
                }
            }
        },
        mounted () {
            if (this.$route.params.id !== undefined) {
                this.getDetail()
            }
        },
        methods: {
            getDetail () {
                this.isLoading.bankAccount = true
                this.$http.get(this.apiUrl.bankAccount + '/' + this.$route.params.id)
                .then(response => {
                    this.model.id = response.data.id
                    this.model.bank_name = response.data.bank_name
                    this.model.bank_account_number = response.data.bank_account_number
                    this.model.bank_account_person = response.data.bank_account_person
                    this.isLoading.bankAccount = false
                    // this.getVouchers()
                    // this.getCustomFields()
                }).catch(error => {
                    this.isLoading.bankAccount = false
                    this.$store.dispatch('notification/error', error)
                    if (typeof error !== 'undefined' && typeof error.response !== 'undefined' && typeof error.response.status !== 'undefined' && error.response.status === 404) {
                        this.$router.push('/notfound')
                    }
                })
            }
        }
    }
</script>