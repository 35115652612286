<template>
    <div>
        <b-row class="mb-2 mt-4">
            <b-col md="12">
                <h5 class="mb-0">Daftar Order</h5>
            </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col md="12" class="mb-1">
              <b-form inline @submit.prevent="doSearch">
                  <b-input placeholder="No. Order..." v-model="model.order_no" />
                  <b-form-select class="ml-2" v-model="model.status"
                  :options="options.order_status"></b-form-select>
                  <b-input-group class="ml-2">
                      <b-form-input
                      style="width: 120px; text-align: center;"
                      v-model="model.start_date"
                      type="text"
                      placeholder="Periode Awal"
                      autocomplete="off"
                      ></b-form-input>
                      <b-input-group-append>
                      <b-form-datepicker
                          v-model="model.start_date"
                          button-only
                          right
                          locale="en-US"
                      ></b-form-datepicker>
                      </b-input-group-append>
                  </b-input-group>
                  <b-input-group class="ml-2">
                      <b-form-input
                      style="width: 120px; text-align: center;"
                      v-model="model.end_date"
                      type="text"
                      placeholder="Periode Akhir"
                      autocomplete="off"
                      ></b-form-input>
                      <b-input-group-append>
                      <b-form-datepicker
                          v-model="model.end_date"
                          button-only
                          right
                          locale="en-US"
                      ></b-form-datepicker>
                      </b-input-group-append>
                  </b-input-group>
                  <b-button type="submit" class="ml-2" variant="secondary"><font-awesome-icon icon="search" /> Cari</b-button>
                  </b-form>
          </b-col>
        </b-row>
        <paginated-table id="order-table" ref="dataTable" :can-delete="true" :can-show="true" :can-edit="true" :can-approve="false" :can-reject="false" :data-url="apiUrl.order" :data-columns="fields" :default-data-params="{'order_group_id': orderGroupId }" :data-params="['order_group_id', 'order_no', 'status', 'product_category_id', 'start_date', 'end_date']">
        </paginated-table>
    </div>
</template>
<script>
  import PaginatedTable from '@/components/PaginatedTable'
  export default {
    components: {
      PaginatedTable
    },
    props: {
        orderGroupId: String,
    },
    watch: {
        '$route.query.order_no': function (val) {
          this.model.order_no = val
          this.doSearch()
        }
    },
    data() {
      return {
        isLoading: false,
        apiUrl: {
          order: process.env.VUE_APP_API_URL + 'orders',
          voucher: process.env.VUE_APP_API_URL + 'product-categories'
        },
        modalModel: {
          order: {
            id: null,
            user: null,
            order_no: null,
            whatsapp_number: null,
            due_datetime: null,
            status: null,
            // payment_method: null,
            // payment_destination: null,
            sub_total: null,
            discount: null,
            total: null,
            unique_number: null,
            buyer_note: {},
            payment: null,
            details: null
          },
          updateStatus: null
        },
        model: {
          product_category_id: this.$route.query.product_category_id,
          order_no: this.$route.query.order_no,
          status: this.$route.query.status,
          start_date: this.$route.query.start_date,
          end_date: this.$route.query.end_date
        },
        options: {
          order_status: [
            { value: null, text: 'Semua Status' },
            { value: 0, text: 'Batal' },
            { value: 1, text: 'Menunggu'},
            { value: 2, text: 'Ditagih'},
            { value: 3, text: 'Selesai'},
          ],
          voucher_types: []
        },
        fields: [
          {
            key: 'user',
            label: 'Customer',
            sort: true
          },
          {
            key: 'order_no',
            label: 'No. Order',
            sort: true
          },
          {
            key: 'product_sku',
            label: 'Kode Produk',
            sortable: true
          },
          {
            key: 'product_name',
            label: 'Produk',
            sortable: true
          },
          {
            key: 'created_at',
            label: 'Tanggal',
            sortable: true
          },
          {
            key: 'due_datetime',
            label: 'Jth.Tempo',
            sortable: true
          },
          {
            key: 'total',
            label: 'Total',
            sortable: true
          },
          {
            key: 'order_status',
            label: 'Status',
            sortable: true
          },
          { 
            key: 'order_actions', 
            label: ''
          }
        ]
      }
    },
    mounted() {
      this.getUrlParams()
    },
    methods: {
      refreshData () {
        this.$refs.dataTable.getDataTable()
      },
      doSearch () {
        this.$refs.dataTable.doFilter(this.model)
      },
      getUrlParams () {
        this.model.order_no = this.model.order_no !== undefined ? this.model.order_no : null
        this.model.status = this.model.status !== undefined ? this.model.status : null
        this.model.product_category_id = this.model.product_category_id !== undefined ? this.model.product_category_id : null
        this.model.start_date = this.model.start_date !== undefined ? this.model.start_date : null
        this.model.end_date = this.model.end_date !== undefined ? this.model.end_date : null
      },
      clearField () {
        this.model.order_no = null
        this.model.status = null
        this.model.product_category_id = null
        this.model.start_date = null
        this.model.end_date = null
      }
    }
  }
</script>