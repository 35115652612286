<template>
    <div>
    <b-breadcrumb :items="[{
        text: 'Daftar Customer',
        href: '#/customers'
    }, {
        text: 'Detail',
        active: true
    }]"></b-breadcrumb>
    <b-card>
        <b-row>
            <b-col md="12">
                <b-row>
                    <b-col md="6">
                        <h3 class="page-title mb-0"> Detail Customer</h3>
                    </b-col>
                    <b-col md="6" class="text-right">
                        <b-button :href="'#/customers/'+$route.params.id+'/edit'" size="md" variant="secondary"><font-awesome-icon icon="edit" /> Edit</b-button>
                    </b-col>
                </b-row>
                <b-col md="8" class="mt-3">
                    <b-row >
                        <b-col md="4">
                            <strong>Name</strong>
                        </b-col>
                        <b-col md="8">
                            {{model.name !== null ? model.name : '-'}}
                        </b-col>
                    </b-row>
                </b-col>
                <b-col md="8" class="mt-3">
                    <b-row >
                        <b-col md="4">
                            <strong>Whatsapp Number</strong>
                        </b-col>
                        <b-col md="8">
                            {{model.whatsapp_number !== null ? model.whatsapp_number : '-'}}
                        </b-col>
                    </b-row>
                </b-col>
                <b-col md="8" class="mt-3">
                    <b-row >
                        <b-col md="4">
                            <strong>Address</strong>
                        </b-col>
                        <b-col md="8">
                            {{model.address !== null ? model.address : '-'}}
                        </b-col>
                    </b-row>
                </b-col>
                <b-col md="8" class="mt-3">
                    <b-row >
                        <b-col md="4">
                            <strong>Facebook URL</strong>
                        </b-col>
                        <b-col md="8">
                            <a :href="model.url_fb" v-if="model.url_fb !== null" target="_blank">{{model.url_fb}}</a>
                            <span v-else>-</span>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col md="8" class="mt-3">
                    <b-row >
                        <b-col md="4">
                            <strong>Email</strong>
                        </b-col>
                        <b-col md="8">
                            {{model.email !== null ? model.email : '-'}}
                        </b-col>
                    </b-row>
                </b-col>
            </b-col>
        </b-row>
    </b-card>
    </div>
</template>
<script>
  import QuillEditor from '@/components/QuillEditor'
    export default {
        components: {
          QuillEditor
        },
        data () {
            return {
                isLoading: {
                    customers: true,
                    subCategory: true,
                    customField: true
                },
                apiUrl: {
                    customers: process.env.VUE_APP_API_URL + 'users',
                },
                model: {
                    id: null,
                    name: null,
                    whatsapp_number: null,
                    url_fb: null,
                    email: null,
                    address: null
                }
            }
        },
        watch: {
            '$route.params.id': function (val) {
                if (val !== undefined) {
                    this.getDetail()
                }
            }
        },
        mounted () {
            if (this.$route.params.id !== undefined) {
                this.getDetail()
            }
        },
        destroyed () {
            this.$root.$off('bv::modal::shown')
        },
        methods: {
            getDetail () {
                this.isLoading.customers = true
                this.$http.get(this.apiUrl.customers + '/' + this.$route.params.id)
                .then(response => {
                    this.model.id = response.data.id
                    this.model.name = response.data.name
                    this.model.whatsapp_number = response.data.whatsapp_number
                    this.model.url_fb = response.data.url_fb
                    this.model.email = response.data.email
                    this.model.address = response.data.address
                    this.isLoading.customers = false
                }).catch(error => {
                    this.isLoading.customers = false
                    this.$store.dispatch('notification/error', error)
                    if (typeof error !== 'undefined' && typeof error.response !== 'undefined' && typeof error.response.status !== 'undefined' && error.response.status === 404) {
                        this.$router.push('/notfound')
                    }
                })
            }
        }
    }
</script>