<template>
    <div>
    <b-breadcrumb :items="[{
        text: 'Sesi Live',
        href: '#/order-groups'
    }, {
        text: 'Detail',
        active: true
    }]"></b-breadcrumb>
    <b-card>
        <b-row>
            <b-col md="12">
                <b-row v-if="model.id !== null">
                    <b-col md="6" v-if="model.title.length > 20">
                        <h3 class="page-title mb-0"><span v-if="!isReadMore">{{model.title.slice(0, 20)}}...</span><span v-else>{{model.title}}</span> <b-button variant="link" class="p-0" @click="isReadMore = !isReadMore">{{!isReadMore? 'read more' : 'read less'}}</b-button>  <b-badge v-if="model.finished_at !== null" variant="danger" class="ml-2">Live</b-badge></h3>
                    </b-col>
                    <b-col md="6" v-else>
                        <h3 class="page-title mb-0">{{model.title}} <b-badge v-if="model.finished_at !== null" variant="danger" class="ml-2">Live</b-badge></h3>
                    </b-col>
                    <b-col md="6" class="text-right">
                        <b-button v-b-tooltip.hover title="Di klik ketika koneksi putus atau sehabis refresh halaman" id="sync-comment" :disabled="isLoading.syncComment" v-if="model.finished_at === null" variant="secondary" class="mr-3" @click="triggerSyncLiveComments()"><font-awesome-icon v-if="isLoading.syncComment" icon="spinner" spin class="mr-3"  />Sync Komentar</b-button>
                        <b-button variant="success" @click="doExportToExcel" v-bind:class="{ 'mr-4' : model.finished_at === null }" :disabled="isLoading.closeOrder"><font-awesome-icon icon="download"></font-awesome-icon> Download Report</b-button>
                        <b-button v-if="model.finished_at === null" @click="closeOrder" variant="primary" :disabled="isLoading.closeOrder"><font-awesome-icon v-if="isLoading.closeOrder" icon="spinner" spin class="mr-2"  />Close</b-button>
                    </b-col>
                    <b-col md="6">
                        <b-row class="mt-3">
                            <b-col md="3">
                                <strong>Jam Mulai</strong>
                            </b-col>
                            <b-col md="9">
                                {{$moment(model.started_at).format('DD-MM-YYYY HH:mm')}}
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col md="6">
                        <b-row class="mt-3">
                            <b-col md="3">
                                <strong>Jam Selesai</strong>
                            </b-col>
                            <b-col md="9">
                                {{model.finished_at !== null ? $moment(model.finished_at).format('DD-MM-YYYY HH:mm') : '-'}}
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col md="12">
                        <order-list v-if="model.id !== null" :order-group-id="model.id" ref="orderList"/>
                    </b-col>
                </b-row>
                <div v-else>
                    <h5 class="text-center"><font-awesome-icon icon="spinner" spin class="mr-1" /> Memuat data</h5>
                </div>
            </b-col>
        </b-row>
    </b-card>
    </div>
</template>
<script>
    import OrderList from '@/pages/OrderGroup/OrderList'
    import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js'

    export default {
        components: {
            OrderList
        },
        data () {
            return {
                isLoading: {
                    orderGroup: true,
                    closeOrder: false,
                    syncComment: false
                },
                isReadMore: false,
                apiUrl: {
                    orderGroup: process.env.VUE_APP_API_URL + 'order-groups',
                    exportOrder: process.env.VUE_APP_API_URL + 'exports/orders',
                    order: process.env.VUE_APP_API_URL + 'orders'
                },
                facebook: {
                    user: null,
                    checking: true,
                    loggedIn: false,
                    loginLoading: false,
                    liveLoading: false
                },
                model: {
                    id: null,
                    title: null,
                    source: null,
                    source_id: null,
                    started_at: null,
                    finished_at: null
                },
                liveCommentsource: null,
                comment_configs: {
                    prefix_word: null,
                    separate_by: ' ',
                    enable_format_list: []
                }
            }
        },
        watch: {
            '$route.params.id': function (val) {
                if (val !== undefined) {
                    this.getDetail(true)
                }
            }
        },
        mounted () {
            if (this.$route.params.id !== undefined) {
                this.getConfigs().then(() => {
                    this.getDetail(true)
                }).catch(error => {
                    console.log(error)
                    this.$store.dispatch('notification/error', 'Gagal memuat halaman, coba lagi')
                    this.isLoading.orderGroup = false
                })
            }
        },
        destroyed () {
            if (this.liveCommentsource !== null) {
                this.liveCommentsource.close()
            }
        },
        methods: {
            serializeComment (comment) {
                const separate_by = this.comment_configs.separate_by === 'space' ? ' ' : this.comment_configs.separate_by
                const format = this.comment_configs.prefix_word + separate_by + this.comment_configs.enable_format_list.join(separate_by)
                const formatKeys = format.replace(this.comment_configs.prefix_word + separate_by, '').split(separate_by)

                // if (!comment.toLowerCase().startsWith(this.comment_configs.prefix_word)) {
                //     return null
                // }
                const arr = comment.replace(this.comment_configs.prefix_word + separate_by, '').split(separate_by)
                let result = []
                formatKeys.forEach((val, index) => {
                    if (val === 'qty') {
                        result[val] = typeof arr[index] !== 'undefined' ? arr[index] : 1
                    } else if (typeof arr[index] !== 'undefined') {
                        result[val] = arr[index]
                    }
                });
                return result
            },
            doExportToExcel () {
                let url = this.apiUrl.exportOrder + '?order_group_id=' + this.$route.params.id
                console.log(this.model.start_date)
                if (this.model.start_date !== undefined && this.model.start_date !== null && this.model.end_date !== undefined &&  this.model.end_date !== null) {
                    url += '&start_date=' + this.model.start_date + '&end_date=' + this.model.end_date
                }
                console.log(url)
                window.open(url)
            },
            getConfigs () {
                return new Promise((resolve, reject) => {
                    if (this.comment_configs.prefix_word !== null) {
                        return resolve()
                    } else {
                        return this.$http.get(process.env.VUE_APP_API_URL + 'web-configs?group=comment-formats').then(response => {
                            response.data.data.forEach(resp => {
                                let val = resp.value
                                if (resp.type === 'list') {
                                    if (val === null) {
                                        val = []
                                    } else {
                                        val = val.split(',')
                                    }
                                }
                                this.comment_configs[resp.key] = val
                            })
                            return resolve()
                        }).catch(error => {
                            return reject(error)
                        })
                    }
                })
            },
            getDetail (needWatchLiveComment) {
                this.isLoading.orderGroup = true
                this.$http.get(this.apiUrl.orderGroup + '/' + this.$route.params.id)
                .then(response => {
                    this.model.id = response.data.id
                    this.model.title = response.data.title
                    this.model.source = response.data.source
                    this.model.source_id = response.data.source_id
                    this.model.started_at = response.data.started_at
                    this.model.finished_at = response.data.finished_at
                    if (this.model.finished_at === null) {
                        if (process.env.NODE_ENV === 'development') {
                            if (needWatchLiveComment) {
                                this.watchLiveComment()
                            }
                        } else {
                            this.facebook.loggedIn = true
                            this.$store.dispatch('facebook/getLoginStatus').then(() => {
                                this.facebook.loggedIn = false
                                if (needWatchLiveComment) {
                                    this.watchLiveComment()
                                }
                            }).catch(error => {
                                this.facebook.loggedIn = false
                                // this.closeOrder()
                                this.$store.dispatch('notification/error', error)
                            })
                        }
                    }
                    this.isLoading.orderGroup = false
                }).catch(error => {
                    this.isLoading.orderGroup = false
                    this.$store.dispatch('notification/error', error)
                    if (typeof error !== 'undefined' && typeof error.response !== 'undefined' && typeof error.response.status !== 'undefined' && error.response.status === 404) {
                        this.$router.push('/notfound')
                    }
                })
            },
            closeOrder () {
                if (confirm('Anda yakin ingin menutup sesi ini?')) {
                    this.isLoading.orderGroup = true
                    this.$http.post(this.apiUrl.orderGroup + '/' + this.model.id +'/close').then(() => {
                        this.isLoading.orderGroup = false
                        this.getDetail(false)
                        this.$store.dispatch('facebook/logout')
                        this.$store.dispatch('notification/success','Sesi berhasil di tutup.')
                    }).catch(error => {
                        this.isLoading.orderGroup = false
                        this.$store.dispatch('notification/error',error)
                    })
                }
            },
            watchLiveComment(){
                this.liveCommentsource = new EventSource("https://streaming-graph.facebook.com/" + this.model.source_id + "/live_comments?access_token=" + this.$store.getters['facebook/getPageToken'] + "&comment_rate=one_per_two_seconds&fields=from{name,id,link},id,message,created_time")
                this.liveCommentsource.onopen = (error) => {
                    this.$store.dispatch('notification/success', 'Sukses menangkap komentar siaran.')
                }
                this.liveCommentsource.onerror = (error) => {
                    this.$store.dispatch('notification/error', 'Gagal menangkap komentar siaran.')
                    this.liveCommentsource.close()
                }
                this.liveCommentsource.onmessage = (event) => {
                    const comment = JSON.parse(event.data)
                    if (comment.message.toLowerCase().startsWith(this.comment_configs.prefix_word)) {
                        this.createOrder(comment).then(() => {
                            this.$refs.orderList.refreshData()
                            this.$store.dispatch('notification/addToCart', comment.message.toUpperCase())
                        }).catch(error => {
                            if (error.response && error.response.data === 'cart_is_empty') {
                                // return resolve(error.response.data)
                                // console.log(error.response.data)
                            } else {
                                console.error(error)
                                this.$store.dispatch('notification/error',error)
                            }
                        })
                    }
                }
            },
            triggerSyncLiveComments () {
                if (confirm('Anda yakin ingin sync ulang komentar?')) {
                    this.isLoading.syncComment = true
                    this.syncLiveComments().then(() => {
                        this.isLoading.syncComment = false
                        this.$refs.orderList.refreshData()
                        this.$store.dispatch('notification/success', 'Sukses mensinkronkan komentar, coba lagi.')
                    }).catch(error => {
                        this.isLoading.syncComment = false
                        console.error(error)
                        this.$store.dispatch('notification/error', 'Gagal mensinkronkan komentar, coba lagi.')
                    })
                }
            },
            syncLiveComments(after = null){
                return new Promise((resolve, reject) => {
                    let doSyncLiveComments = (afterToken = null) => {
                        window.FB.api(this.model.source_id + '/comments?fields=from{name,id,link},id,message,created_time', {
                            limit: 500,
                            access_token: this.$store.getters['facebook/getPageToken'],
                            after: afterToken,
                            order: 'chronological'
                        }, (response) => {
                            if (!response || response.error) {
                                if (response.error.error_subcode) {
                                    return resolve([])
                                }
                                return reject(!response ? 'Gagal mensinkronkan data.' : response.error)
                            } else {
                                if (response.data.length > 0) {
                                    let orders = []
                                    response.data.forEach(comment => {
                                        if (comment.message.toLowerCase().startsWith(this.comment_configs.prefix_word)) {
                                            orders.push(this.prepareFormData(comment))
                                        }
                                    })
                                    if (orders.length) {
                                        return this.$http.post(this.apiUrl.order + '/sync-orders', {
                                            orders: orders
                                        }).then(() => {
                                            if (typeof response.paging.next !== 'undefined') {
                                                return doSyncLiveComments(response.paging.cursors.after)
                                            } else {
                                                return resolve(orders)
                                            }
                                        }).catch(error => {
                                            this.isLoading = false
                                            return reject(error)
                                        })
                                    } else {
                                        return resolve(orders)
                                    }
                                } else {
                                    return resolve([])
                                }
                            }
                        })
                    }
                    return doSyncLiveComments()
                })
            },
            prepareFormData (orderData) {
                let buyer_note = null
                if (typeof orderData.from != "undefined") {
                    buyer_note = 'Order oleh: ' + orderData.from.name
                }
                const comment = this.serializeComment(orderData.message.toLowerCase())
                let customerName = null
                let whatsappNumber = null
                if (typeof comment.phone_number !== 'undefined') {
                    if (isValidPhoneNumber(comment.phone_number.trim(), 'ID')) {
                        whatsappNumber = parsePhoneNumber(comment.phone_number.trim(), 'ID').number
                        if (typeof orderData.from.name !== 'undefined') {
                            customerName = orderData.from.name.trim()
                        }
                        // whatsappNumber = whatsappNumber.replace('+', '')
                    }
                }
                return {
                    carts: [
                        {
                            sku_id: comment.sku_id,
                            quantity: typeof comment.quantity !== 'undefined' ? comment.quantity : 1
                        }
                    ],
                    whatsapp_number: whatsappNumber,
                    customer_name: customerName,
                    // phone_number: typeof comment.phone_number !== 'undefined' ? comment.phone_number : null,
                    discount: null,
                    buyer_note: buyer_note,
                    ref_id : orderData.id,
                    order_datetime : this.$moment(orderData.created_time).format('DD-MM-YYYY HH:mm:ss'),
                    order_group_id: this.$route.params.id
                }
            },
            createOrder (orderData) {
                return new Promise((resolve, reject) => {
                    this.$http.post(this.apiUrl.order, this.prepareFormData(orderData)).then(resp => {
                        // JANGAN LUPA: masukkan array ke list order
                        return resolve(resp)
                    }).catch(error => {
                        return reject(error)
                    })
                })
            },
        }
    }
</script>