<template>
    <div class="container">
    <b-row>
        <b-col md="12" class="text-right mt-3 mb-3">
            <b-button type="button" size="md" @click="print" variant="success" class="mr-3" :disabled="isLoading.payment"><font-awesome-icon icon="print" /> Cetak Incoive</b-button>
        </b-col>
    </b-row>
    <b-card id="print_to_pdf" class="no-border">
        <b-row>
            <b-col md="6">
                <b-row class="mb-3">
                    <b-col md="12">
                        <img src="/assets/images/logo.jpeg" alt="" style="max-width: 70px;">
                        <div><span style="font-size: 30px; font-weight: 700; padding-top: 10px;">SHOPPFARIZ</span></div>
                    </b-col>
                </b-row>
                <b-row class="mb-3">
                    <b-col md="12">
                        <h5>Tujuan Pengiriman:</h5>
                    </b-col>
                </b-row>
                <b-row class="mb-3">
                    <b-col md="12">
                        <strong>{{model.user !== null ? model.user.name : '-'}} <span v-if="model.user !== null && model.user.whatsapp_number"> - {{model.user.whatsapp_number}}</span></strong>
                    </b-col>
                    <b-col md="12" v-if="model.user !== null && model.user.address">
                        {{model.user.address}}
                    </b-col>
                    <b-col md="12" v-if="model.user !== null && model.user.email">
                        {{model.user.email}}
                    </b-col>
                </b-row>
            </b-col>
            <b-col md="6">
                <b-row class="mb-3 text-right">
                    <b-col md="12"><h2>INVOICE</h2></b-col>
                    <b-col md="12"><h2>#{{model.order_no}}</h2></b-col>
                </b-row>
                <b-row class="mb-3 text-right">
                    <b-col md="6" class="text-right">Tanggal:</b-col>
                    <b-col md="6">{{$moment(model.order_datetime).format('DD-MM-YYYY HH:mm')}}</b-col>
                </b-row>
                <b-row class="mb-3 text-right">
                    <b-col md="6" class="text-right">Jatuh Tempo:</b-col>
                    <b-col md="6">{{$moment(model.due_datetime).format('DD-MM-YYYY HH:mm')}}</b-col>
                </b-row>
                <b-row class="mb-3 text-right">
                    <b-col md="6" class="text-right"><h3><strong>Total Harga</strong></h3></b-col>
                    <b-col md="6"><h3><strong>Rp. {{$formatPrice(model.total)}}</strong></h3></b-col>
                </b-row>
            </b-col>
            <b-col md="12" class="mt-3">
                <h5>Rincian Pesanan</h5>
                <table class="table table-striped mt-1 mb-0">
                    <thead>
                        <tr>
                            <th width="40%" class="text-left">Kode</th>
                            <th width="40%" class="text-left">Nama Produk</th>
                            <th width="10%" class="text-center">Qty</th>
                            <th width="25%" class="text-right">Harga</th>
                            <th width="25%" class="text-right">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-if="model.details.length > 0">
                            <tr v-for="detail in model.details" :key="detail.id">
                                <td>{{detail.product !== null ? detail.product.sku_id : '-'}}</td>
                                <td>{{detail.product !== null ? detail.product.name : '-'}}</td>
                                <td class="text-center">{{detail.quantity}}</td>
                                <td class="text-right">{{$formatPrice(detail.price)}}</td>
                                <td class="text-right">{{$formatPrice(detail.total)}}</td>
                            </tr>
                        </template>
                        <tr v-else>
                            <td colspan="5">Tidak data untuk saat ini</td>
                        </tr>
                        <tr>
                            <td colspan="4"><strong>Sub Total</strong></td>
                            <td class="text-right">{{$formatPrice(model.sub_total)}}</td>
                        </tr>
                        <tr>
                            <td colspan="4"><strong>Diskon</strong></td>
                            <td class="text-right">{{$formatPrice(model.discount)}}</td>
                        </tr>
                        <tr>
                            <td colspan="4"><strong>Ongkos Kirim</strong></td>
                            <td class="text-right">{{$formatPrice(model.shipping_fee)}}</td>
                        </tr>
                        <tr>
                            <td colspan="4"><strong>Total</strong></td>
                            <td class="text-right">{{$formatPrice(model.total)}}</td>
                        </tr>
                    </tbody>
                </table>
            </b-col>
        </b-row>
    </b-card>
    </div>
</template>
<script>
    import Vue from 'vue'
    import Autocomplete from '@trevoreyre/autocomplete-vue'
    import '@trevoreyre/autocomplete-vue/dist/style.css'
    import VueHtmlToPaper from 'vue-html-to-paper'

    const options = {
        name: '_blank',
        specs: [
            'fullscreen=yes',
            'titlebar=yes',
            'scrollbars=yes'
        ],
        styles: [
            'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
            'https://unpkg.com/kidlat-css/css/kidlat.css'
        ],
        timeout: 1000, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: window.document.title, // override the window title
    }
    Vue.use(VueHtmlToPaper, options)

    Vue.use(Autocomplete)
    export default {
        data () {
            return {
                isLoading: {
                    order: true,
                    payment: false,
                    status: false
                },
                apiUrl: {
                    order: process.env.VUE_APP_API_URL + 'orders',
                    customer: process.env.VUE_APP_API_URL + 'users'
                },
                model: {
                    id: null,
                    buyer_note: null,
                    created_at: null,
                    details: [],
                    discount: null,
                    due_datetime: null,
                    order_no: null,
                    ref_id: null,
                    status: null,
                    sub_total: null,
                    total: null,
                    unique_number: null,
                    user: null
                },
                options: {
                    customerTypes: [
                        { text: 'Yang sudah ada', value: 'old' },
                        { text: 'Customer Baru', value: 'new' }
                    ]
                },
                modalModel: {
                    shipping_fee: null,
                    due_datetime: null,
                    discount: null,
                    customerType: 'old',
                    name: null,
                    email: null,
                    whatsapp_number: null,
                    url_fb: null,
                    address: null,
                    user_id: null
                }
            }
        },
        watch: {
            '$route.params.id': function (val) {
                if (val !== undefined) {
                    this.getDetail()
                }
            }
        },
        mounted () {
            if (this.$route.params.id !== undefined) {
                this.getDetail()
            }
        },
        methods: {
            getDetail () {
                this.isLoading.order = false
                this.$http.get(this.apiUrl.order + '/' + this.$route.params.id + '?payment_confirmation=1')
                .then(response => {
                    this.model.id = response.data.id
                    this.model.buyer_note = response.data.buyer_note
                    this.model.type = response.data.type
                    this.model.created_at = response.data.created_at
                    this.model.details = response.data.details
                    this.model.discount = response.data.discount
                    this.model.due_datetime = response.data.due_datetime
                    this.model.order_no = response.data.order_no
                    this.model.ref_id = response.data.ref_id
                    this.model.status = response.data.status
                    this.model.sub_total = response.data.sub_total
                    this.model.shipping_fee = response.data.shipping_fee
                    this.model.total = response.data.total
                    this.model.unique_number = response.data.unique_number
                    this.model.user = response.data.user
                    this.isLoading.order = false
                }).catch(error => {
                    this.isLoading.order = false
                    this.$store.dispatch('notification/error', error)
                    if (typeof error !== 'undefined' && typeof error.response !== 'undefined' && typeof error.response.status !== 'undefined' && error.response.status === 404) {
                        this.$router.push('/notfound')
                    }
                })
            },
            doSubmitCustomer () {
                this.isLoading.status = true
                if (this.modalModel.customerType === 'old' || this.model.user !== null) {
                    this.$http.patch(this.apiUrl.order + '/' + this.$route.params.id + '/user', {
                        user_id: this.model.user !== null ? this.model.user.id : this.modalModel.user_id,
                        discount: this.modalModel.discount,
                        due_datetime: this.modalModel.due_datetime,
                        shipping_fee: this.modalModel.shipping_fee
                    }).then(response => {
                        this.isLoading.status = false
                        this.$bvModal.hide('modal-order-billing')
                        this.getDetail()
                    }).catch(error => {
                        this.isLoading.status = false
                        this.$store.dispatch('notification/error', error)
                    })
                } else {
                    console.log({
                        name: this.modalModel.name,
                        email: this.modalModel.email,
                        role: 'customer',
                        whatsapp_number: this.modalModel.whatsapp_number,
                        url_fb: this.modalModel.url_fb,
                        address: this.modalModel.address,
                        order_id: this.$route.params.id,
                        discount: this.modalModel.discount,
                        due_datetime: this.modalModel.due_datetime,
                        shipping_fee: this.modalModel.shipping_fee
                    })
                    this.$http.post(this.apiUrl.customer, {
                        name: this.modalModel.name,
                        email: this.modalModel.email,
                        role: 'customer',
                        whatsapp_number: this.modalModel.whatsapp_number,
                        url_fb: this.modalModel.url_fb,
                        address: this.modalModel.address,
                        order_id: this.$route.params.id,
                        discount: this.modalModel.discount,
                        due_datetime: this.modalModel.due_datetime,
                        shipping_fee: this.modalModel.shipping_fee
                    }).then(response => {
                        this.isLoading.status = false
                        this.$bvModal.hide('modal-order-billing')
                        this.getDetail()
                    }).catch(error => {
                        this.isLoading.status = false
                        this.$store.dispatch('notification/error', error)
                    })
                }
            },
            doUpdateStatus (value) {
                this.isLoading.status = true
                this.$http.patch(this.apiUrl.order + '/' + this.$route.params.id + '/status', {
                    status: value
                }).then(response => {
                    this.getDetail()
                    this.isLoading.status = false
                }).catch(error => {
                    this.isLoading = false
                    this.$store.dispatch('notification/error', error)
                })
            },
            openBillingModal () {
                this.$bvModal.show('modal-order-billing')
            },
            searchCustomer(value) {
                return new Promise(resolve => {
                    if (value.length < 3) {
                        return resolve([])
                    }

                    this.$http.get(this.apiUrl.customer + '?role=customer&name=' + value)
                    .then(response => {
                        resolve(response.data.data)
                    })
                })
            },
            getResultValue(result) {
                return result.name
            },
            handleSubmit(result) {
                this.modalModel.user_id = result.id
            },
            print () {
                this.$htmlToPaper('print_to_pdf');
            }
        }
    }
</script>