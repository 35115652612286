<template>
  <div>
  <b-breadcrumb v-if="model.id !== null" :items="[{
        text: 'Daftar Staff',
        href: '#/users'
    }, {
        text: 'Detail Staff',
        href: '#/users/' + model.id
    }, {
        text: 'Ubah Staff',
        active: true
  }]"></b-breadcrumb>
  <b-breadcrumb v-else :items="[{
    text: 'Daftar Staff',
    href: '/#/users'
  }, {
    text: 'Tambah Staff',
    active: true
  }]"></b-breadcrumb>
  <b-card :title="(model.id !== null ? 'Edit' : 'Tambah') + ' Staff'" style="max-width: 500px;margin: auto;">
      <b-form @submit.prevent="onSubmit">
        <b-form-group id="input-group-1" label="Nama *:" label-for="input-1">
            <b-form-input
            id="input-1"
            v-model="model.name"
            placeholder="Ketikkan nama"
            required
            ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-4" label="No WA*:" label-for="input-4">
            <b-form-input
            id="input-4"
            v-model="model.whatsapp_number"
            type="number"
            placeholder="Ketikkan No. WA"
            ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-2" label="Email:" label-for="input-2">
            <b-form-input
            id="input-2"
            type="email"
            v-model="model.email"
            placeholder="Ketikkan email"
            ></b-form-input>
        </b-form-group>
        <!-- <b-form-group id="input-group-3" label="Username*:" label-for="input-3">
            <b-form-input
            id="input-3"
            v-model="model.username"
            placeholder="Ketikkan username"
            required
            ></b-form-input>
        </b-form-group> -->
        <b-form-checkbox v-if="model.id === null" class="mb-2" id="checkbox-1" v-model="model.generate_password" name="checkbox-1" :value="1" :unchecked-value="0">Kirim random password ke email user</b-form-checkbox>
        <b-form-group v-if="model.id === null && model.generate_password === 0" id="input-group-4" label="Password:" label-for="input-4">
            <b-input-group>
                <b-form-input
                :type="modelType.password"
                id="input-4"
                v-model="model.password"
                placeholder="Ketikkan password"
                ></b-form-input>
                <b-input-group-append>
                    <b-button variant="secondary" v-if="modelType.password === 'password'" @click.prevent="modelType.password = 'text'"><font-awesome-icon icon="eye" /></b-button>
                    <b-button variant="secondary" v-else @click.prevent="modelType.password = 'password'"><font-awesome-icon icon="eye-slash" /></b-button>
                </b-input-group-append>
            </b-input-group>
        </b-form-group>
      <b-button type="submit" variant="primary" :disabled="isLoading"><font-awesome-icon v-if="isLoading"  icon="spinner" spin /> Simpan</b-button>
    </b-form>
  </b-card>
  </div>
</template>
<script>
    export default {
        data () {
            return {
                isLoading: false,
                apiUrl: {
                    user: process.env.VUE_APP_API_URL + 'users',
                    company: process.env.VUE_APP_API_URL + 'companies'
                },
                modelType: {
                    password: 'password'
                },
                options: {
                    companies: []
                },
                model: {
                    id: null,
                    role: 'staff',
                    name: null,
                    username: null,
                    whatsapp_number: null,
                    email: null,
                    generate_password: 1
                }
            }
        },
        watch: {
            '$route.params.id': function (val) {
                if (val !== undefined) {
                    this.getDetail()
                } else {
                    this.resetForm()
                }
            }
        },
        mounted () {
            if (this.$route.params.id !== undefined) {
                this.getDetail()
            }
            this.getCompany()
        },
        methods: {
            resetForm () {
                this.model.id = null
                this.model.name = null
                this.model.username = null
                this.model.email = null
                this.model.whatsapp_number = null
                this.model.generate_password = 0
            },
            onSubmit () {
                this.isLoading = true
                const formData = new FormData()
                if (this.model.id !== null && this.model.id !== '') {
                    formData.append('_method', 'PATCH');
                }
                if (this.model.role !== null && this.model.role !== '') {
                    formData.append('role', this.model.role);
                }
                if (this.model.name !== null && this.model.name !== '') {
                    formData.append('name', this.model.name);
                }
                // if (this.model.username !== null && this.model.username !== '') {
                //     formData.append('username', this.model.username);
                // }
                if (this.model.whatsapp_number !== null && this.model.whatsapp_number !== '') {
                    formData.append('whatsapp_number', this.model.whatsapp_number);
                }
                if (this.model.email !== null && this.model.email !== '') {
                    formData.append('email', this.model.email);
                }
                if (this.model.company_id !== null && this.model.company_id !== '') {
                    formData.append('company_id', this.model.company_id);
                }
                if (this.model.generate_password === 0 && this.model.id === null) {
                    formData.append('password', this.model.password);
                }
                if (this.model.id === null) {
                    formData.append('generate_password', this.model.generate_password);
                }
                this.$http.post(this.apiUrl.user + (this.model.id !== null && this.model.id !== '' ? '/' + this.model.id : ''), formData).then(response => {
                    this.isLoading = false
                    if (this.model.id !== null && this.model.id !== '') {
                        this.$store.dispatch('notification/success', 'Staff berhasil diubah.')
                    } else {
                        this.$store.dispatch('notification/success', 'Staff berhasil disimpan.')
                    }
                    this.$router.push('/users/' + response.data.id)
                }).catch(error => {
                    this.isLoading = false
                    this.$store.dispatch('notification/error', error)
                })
            },
            getCompany () {
                this.isLoading = true
                this.$http.get(this.apiUrl.company + '?limit=1000')
                .then(response => {
                    this.options.companies = []
                    response.data.data.forEach(data => {
                        this.options.companies.push({
                            value: data.id,
                            text: data.name
                        })
                    })
                    this.isLoading = false
                }).catch(error => {
                    this.isLoading = false
                    this.$store.dispatch('notification/error', error)
                    if (typeof error !== 'undefined' && typeof error.response !== 'undefined' && typeof error.response.status !== 'undefined' && error.response.status === 404) {
                        this.$router.push('/notfound')
                    }
                })
            },
            getDetail () {
                this.isLoading = true
                this.$http.get(this.apiUrl.user + '/' + this.$route.params.id)
                .then(response => {
                    this.model.id = response.data.id
                    this.model.name = response.data.name
                    this.model.company_id = response.data.company_id
                    this.model.username = response.data.username
                    this.model.email = response.data.email
                    this.model.whatsapp_number = response.data.whatsapp_number
                    this.isLoading = false
                }).catch(error => {
                    this.isLoading = false
                    this.$store.dispatch('notification/error', error)
                    if (typeof error !== 'undefined' && typeof error.response !== 'undefined' && typeof error.response.status !== 'undefined' && error.response.status === 404) {
                        this.$router.push('/notfound')
                    }
                })
            }
        }
    }
</script>