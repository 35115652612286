<template>
  <div>
  <b-breadcrumb :items="[{
    text: 'Daftar Order',
    href: '/#/orders'
  }, {
    text: 'Tambah Order',
    active: true
  }]"></b-breadcrumb>
  <b-card :title="'Tambah Order'" style="max-width: 500px;margin: auto;">
      <b-form @submit.prevent="onSubmit">
        <b-form-group id="input-group-4" label="Kategori*:" label-for="input-4">
            <b-form-select id="input-4" @change="getProducts(model.product_category_id)" required v-model="model.product_category_id" :options="options.productCategories">
                <template #first>
                    <b-form-select-option :value="null" disabled>-- Pilih kategori --</b-form-select-option>
                </template>
            </b-form-select>
        </b-form-group>
        <b-form-group id="input-group-4" label="Produk*:" label-for="input-4">
            <b-form-select id="input-4" required v-model="model.sku_id" :options="options.products">
                <template #first>
                    <b-form-select-option :value="null" disabled>-- Pilih produk --</b-form-select-option>
                </template>
            </b-form-select>
        </b-form-group>
        <b-form-group id="input-group-8" label="Kuantitas:" label-for="input-8">
            <b-form-input
            type="number"
            id="input-8"
            v-model="model.quantity"
            placeholder="Kuantitas"
            ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-1" label-for="input-1">
            <label>Tanggal Order<sup class="text-danger"></sup>:</label>
            <b-input-group-append>
            <b-form-input
                style="width: 150px; text-align: left;"
                v-model="model.order_datetime"
                type="text"
                placeholder="Pilih tanggal order"
                autocomplete="off"
            ></b-form-input>
            <b-form-datepicker
                v-model="model.order_datetime"
                button-only
                right
                locale="en-US"
            ></b-form-datepicker>
            </b-input-group-append>
        </b-form-group>
      <b-button type="submit" variant="primary" :disabled="isLoading"><font-awesome-icon v-if="isLoading"  icon="spinner" spin /> Simpan</b-button>
    </b-form>
  </b-card>
  </div>
</template>
<script>
    // import QuillEditor from '@/components/QuillEditor'

    export default {
        // components: {
        //   QuillEditor
        // },
        data () {
            return {
                isLoading: false,
                apiUrl: {
                    order: process.env.VUE_APP_API_URL + 'orders',
                    product: process.env.VUE_APP_API_URL + 'products',
                    productCategory: process.env.VUE_APP_API_URL + 'product-categories'
                },
                model: {
                    product_category_id: null,
                    quantity: null,
                    sku_id: null,
                    stock: 0,
                    product_id: null,
                    order_datetime: null
                },
                options: {
                    productCategories: [],
                    products: []
                }
            }
        },
        mounted () {
            this.getProductCategories()
        },
        methods: {
            onSubmit () {
                this.isLoading = true
                let modelData = {
                    order_datetime: null
                }
                modelData.order_datetime = this.model.order_datetime
                modelData.buyer_note = 'Order Oleh: ' + this.$store.getters['account/accountData'].name
                modelData.carts = [
                    {
                        sku_id: this.model.sku_id,
                        quantity: this.model.quantity
                    }
                ]
                modelData.type = 'Order Via Whatsapp'
                this.$http.post(this.apiUrl.order, modelData).then(response => {
                    this.isLoading = false
                    this.$store.dispatch('notification/success', 'Order berhasil disimpan.')
                    this.$router.push('/orders/' + response.data.id)
                }).catch(error => {
                    this.isLoading = false
                    this.$store.dispatch('notification/error', error)
                })
            },
            getProductCategories () {
                this.isLoading = true
                this.$http.get(this.apiUrl.productCategory)
                .then(response => {
                    this.options.productCategories = []
                    response.data.data.forEach(parent => {
                        this.options.productCategories.push({
                            value: parent.id,
                            text: parent.name
                        })
                    })
                    this.isLoading = false
                }).catch(error => {
                    this.isLoading = false
                    this.$store.dispatch('notification/error', error)
                })
            },
            getProducts (productCategoriesId) {
                this.isLoading = true
                this.$http.get(this.apiUrl.product + '?limit=1000&product_category_id=' + productCategoriesId)
                .then(response => {
                    this.options.products = []
                    response.data.data.forEach(data => {
                        this.options.products.push({
                            value: data.sku_id,
                            text: data.sku_id + ' - ' + data.name
                        })
                    })
                    this.isLoading = false
                }).catch(error => {
                    this.isLoading = false
                    this.$store.dispatch('notification/error', error)
                })
            }
        }
    }
</script>