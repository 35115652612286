<template>
    <div>
        <b-card class="p-0 mt-4">
          <b-row class="mb-2">
            <b-col md="12">
              <h4 class="card-title">Laporan Penjualan</h4>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col md="9">
              <b-form inline @submit.prevent="doSearch">
                <b-input-group class="ml-2">
                  <b-form-input
                    style="width: 120px; text-align: center;"
                    v-model="model.start_date"
                    type="text"
                    placeholder="Periode Awal"
                    autocomplete="off"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="model.start_date"
                      button-only
                      right
                      locale="en-US"
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>
                <b-input-group class="ml-2">
                  <b-form-input
                    style="width: 120px; text-align: center;"
                    v-model="model.end_date"
                    type="text"
                    placeholder="Periode Akhir"
                    autocomplete="off"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="model.end_date"
                      button-only
                      right
                      locale="en-US"
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>
                <b-button type="submit" class="ml-2" variant="secondary"><font-awesome-icon icon="search" /> Cari</b-button>
              </b-form>
            </b-col>
            <b-col md="3 text-right">
              <b-button size="md" variant="success" @click="doExportToExcel">
                <font-awesome-icon icon="download"></font-awesome-icon> Download Report
              </b-button>
            </b-col>
          </b-row>
          <paginated-table id="order-table" ref="dataTable" :can-delete="true" :can-show="true" :can-edit="true" :can-approve="false" :can-reject="false" :data-url="apiUrl.order" :data-columns="fields" :default-data-params="{'with_payment': 1 }" :data-params="['with_payment', 'order_no', 'status', 'product_category_id', 'start_date', 'end_date']">
          </paginated-table>
        </b-card>
        
    </div>
</template>
<script>
  import PaginatedTable from '@/components/PaginatedTable'
  export default {
    components: {
      PaginatedTable
    },
    watch: {
        '$route.query.order_no': function (val) {
          this.model.order_no = val
          this.doSearch()
        }
    },
    data() {
      return {
        isLoading: false,
        apiUrl: {
          order: process.env.VUE_APP_API_URL + 'orders',
          exportOrder: process.env.VUE_APP_API_URL + 'exports/orders',
          voucher: process.env.VUE_APP_API_URL + 'product-categories'
        },
        modalModel: {
          order: {
            id: null,
            user: null,
            order_no: null,
            whatsapp_number: null,
            due_datetime: null,
            status: null,
            // payment_method: null,
            // payment_destination: null,
            sub_total: null,
            discount: null,
            total: null,
            unique_number: null,
            buyer_note: {},
            payment: null,
            details: null
          },
          updateStatus: null
        },
        model: {
          product_category_id: this.$route.query.product_category_id,
          order_no: this.$route.query.order_no,
          status: this.$route.query.status,
          start_date: this.$route.query.start_date,
          end_date: this.$route.query.end_date
        },
        options: {
          order_status: [
            { value: null, text: 'Semua Status' },
            { value: 0, text: 'Batal' },
            { value: 1, text: 'Menunggu'},
            { value: 2, text: 'Ditagih'},
            { value: 3, text: 'Selesai'},
          ],
          voucher_types: []
        },
        fields: [
          {
            key: 'user',
            label: 'Customer',
            sort: true
          },
          {
            key: 'userAddress',
            label: 'Alamat',
            sort: true
          },
          {
            key: 'order_no',
            label: 'No. Order',
            sort: true
          },
          {
            key: 'created_at',
            label: 'Tanggal',
            sortable: true
          },
          {
            key: 'due_datetime',
            label: 'Jth.Tempo',
            sortable: true
          },
          {
            key: 'order_status',
            label: 'Status',
            sortable: true
          },
          {
            key: 'product_sku',
            label: 'Kode Produk',
            sortable: true
          },
          {
            key: 'product_name',
            label: 'Produk',
            sortable: true
          },
          {
            key: 'product_qty',
            label: 'Qty',
            sortable: true
          },
          {
            key: 'product_selling_price',
            label: 'Harga',
            sortable: true
          },
          {
            key: 'order_detail_subtotal',
            label: 'Subtotal',
            sortable: true
          },
          {
            key: 'discount',
            label: 'Diskon',
            sortable: true
          },
          {
            key: 'total',
            label: 'Total',
            sortable: true
          }
        ]
      }
    },
    mounted() {
      this.getUrlParams()
    },
    methods: {
      doSearch () {
        this.$refs.dataTable.doFilter(this.model)
      },
      getUrlParams () {
        this.model.order_no = this.model.order_no !== undefined ? this.model.order_no : null
        this.model.status = this.model.status !== undefined ? this.model.status : null
        this.model.product_category_id = this.model.product_category_id !== undefined ? this.model.product_category_id : null
        this.model.start_date = this.model.start_date !== undefined ? this.model.start_date : null
        this.model.end_date = this.model.end_date !== undefined ? this.model.end_date : null
      },
      clearField () {
        this.model.order_no = null
        this.model.status = null
        this.model.product_category_id = null
        this.model.start_date = null
        this.model.end_date = null
      },
      doExportToExcel () {
        let url = this.apiUrl.exportOrder
        if (this.model.start_date !== undefined && this.model.start_date !== null && this.model.end_date !== undefined &&  this.model.end_date !== null) {
          url += '?start_date=' + this.model.start_date + '&end_date=' + this.model.end_date
        }
        window.open(url)
      },
      // checkPaymentStatus () {
      //   this.isLoading = true
      //   this.$http.post(this.apiUrl.order + '/refresh-payment-status/' + this.modalModel.order.id).then(response => {
      //     this.modalModel.order.status = response.data
      //     this.isLoading = false
      //     this.$refs.dataTable.getDataTable()
      //   }).catch(error => {
      //     this.isLoading = false
      //     this.$store.dispatch('notification/error', error)
      //   })
      // },
      // openOrderModal (row) {
      //   this.modalModel = {
      //     order: {
      //       id: row.item.id,
      //       user: row.item.user,
      //       order_no: row.item.order_no,
      //       whatsapp_number: row.item.whatsapp_number,
      //       due_datetime: row.item.due_datetime,
      //       status: row.item.status,
      //       // payment_method: row.item.payment_method,
      //       // payment_destination: row.item.payment_destination,
      //       payment: row.item.payment,
      //       sub_total: row.item.sub_total,
      //       discount: row.item.discount,
      //       total: row.item.total,
      //       unique_number: row.item.unique_number,
      //       buyer_note: row.item.buyer_note,
      //       // details: row.item.details[0]
      //     }
      //   }
      //   this.modalModel.updateStatus = row.item.status
      //   this.$bvModal.show('modal-order-detail')
      // },
      // updateOrderStatus (bvModalEvt) {
      //   bvModalEvt.preventDefault()
      //   this.isLoading = true
      //   this.$http.patch(this.apiUrl.order + '/' + this.modalModel.order.id, {
      //     status: this.modalModel.updateStatus
      //   }).then(response => {
      //       this.isLoading = false
      //       this.$bvModal.hide('modal-order-update-status')
      //       this.$bvModal.hide('modal-order-detail')
      //       this.$refs.dataTable.getDataTable()
      //   }).catch(error => {
      //       this.isLoading = false
      //       this.$store.dispatch('notification/error', error)
      //   })
      // }
    }
  }
</script>