<template>
    <div>
        <b-modal size="md" id="modal-quill-insertimage" title="Tambahkan Gambar" cancel-title="Batal" no-close-on-backdrop no-close-on-esc>
            <b-form-group id="input-image-url-group" label="Masukkan gambar melalui url" label-for="input-image-url">
                <b-form-input
                id="input-image-url"
                v-model="model.insertImage.url"
                placeholder="Masukkan URL Gambar"
                ></b-form-input>
            </b-form-group>
            <hr style="margin-left: -1rem; margin-right: -1rem;">
            <b-form-group id="upload-image-url-group" label="Upload gambar" label-for="upload-image-url">
                <b-form-file
                accept="image/jpeg, image/png, image/gif"
                id="upload-image-url"
                v-model="model.insertImage.upload"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                ></b-form-file>
            </b-form-group>
            <template #modal-footer>
                <b-button type="button" variant="secondary" @click.prevent="$bvModal.hide('modal-quill-insertimage')">Batal</b-button>
                <b-button type="button" variant="primary" @click.prevent="submitImageToEditor" :disabled="isLoading.insertImage"><font-awesome-icon v-if="isLoading.insertImage"  icon="spinner" spin /> Masukkan</b-button>
            </template>
        </b-modal>
        <div id="quill-toolbar">
            <span class="ql-formats">
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-underline"></button>
            </span>
            <span class="ql-formats">
                <select class="ql-align">
                    <option selected></option>
                    <option value="center"></option>
                    <option value="right"></option>
                    <option value="justify"></option>
                </select>
            </span>
            <span class="ql-formats">
                <select class="ql-header">
                    <option value="1"></option>
                    <option value="2"></option>
                    <option value="3"></option>
                    <option value="4"></option>
                    <option selected></option>
                </select>
            </span>
            <span class="ql-formats">
                <button class="ql-list" value="ordered"></button>
                <button class="ql-list" value="bullet"></button>
            </span>
            <span class="ql-formats">
                <button type="button" class="ql-link"></button>
                <button type="button" class="btn-custom-image" @click.prevent="openInsertImageModal"><font-awesome-icon icon="image" class="icon"/></button>
            </span>
        </div>
        <div id="quill-editor" style="height: 100%; min-height: 300px;"></div>
    </div>
</template>
<script>
    import Quill from 'quill'
    import Vue from 'vue'

    export default {
        props: {
            initValue: {
                type: String,
                default: null
            },
        },
        data () {
            return {
                isLoading: {
                    insertImage: false
                },
                chunk: {
                    lastChunkProcessed: 0,
                    fileStamp: null,
                    filename: null
                },
                model: {
                    insertImage: {
                        url: null,
                        upload: null
                    }
                },
                editorValue: null,
                editor: null
            }
        },
        mounted () {
            Vue.nextTick(() => {
                if (this.editor === null) {
                    Quill.register(Quill.import('attributors/style/align'), true)

                    this.editor = new Quill(document.getElementById('quill-editor'), {
                        placeholder: 'Ketik disini..',
                        theme: 'snow',
                        modules: {
                            toolbar: '#quill-toolbar'
                            // toolbar: [
                            //     [{ 'header': [1, 2, 3, 4, false] }],
                            //     ['bold', 'italic', 'underline'],
                            //     [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                            //     ['link'],
                            //     [{'image': ['Upload', 'Insert URL']}]
                            // ]
                        }
                    })
                    this.editor.on('editor-change', () => {
                        // console.log(this.editor.getContents());
                        this.$emit('editorchange', this.editor.root.innerHTML)
                    })

                    setTimeout(() => {
                        if (this.initValue !== null) {
                            this.setEditorValue(this.initValue)
                        }
                        this.editor.focus()
                    }, 500)
                }
            })
        },
        watch: {
            'model.insertImage.upload': function (val) {
                this.resetlastChunkProcessed()
                if (val !== null) {
                    this.setFileName(val)
                }
            }
        },
        methods: {
            setEditorValue (val) {
                if (this.editor !== null) {
                    this.editor.root.innerHTML = val
                }
            },
            openInsertImageModal () {
                this.$bvModal.show('modal-quill-insertimage')
            },
            submitImageToEditor (bvModalEvt) {
                // https://cdn.sstatic.net/Img/unified/sprites.svg
                bvModalEvt.preventDefault()
                if (this.model.insertImage.url !== null && this.model.insertImage.url !== '') {
                    this.isLoading.insertImage = true
                    const img = new Image()
                    img.onerror = img.onabort = () => {
                        this.isLoading.insertImage = false
                        this.$store.dispatch('notification/error', 'Gambar tidak dapat dimuat, silahkan coba lagi.')
                    }
                    img.onload = () => {
                        this.insertImageToEditor(this.model.insertImage.url)
                        this.model.insertImage.url = null
                        this.isLoading.insertImage = false
                        this.$bvModal.hide('modal-quill-insertimage')
                    }
                    img.src = this.model.insertImage.url
                } else if (this.model.insertImage.upload !== null && this.model.insertImage.upload !== '') {
                    if (this.model.insertImage.upload.size > 10000000) {
                        this.$store.dispatch('notification/error', 'Ukuran file tidak dapat melebihi 10MB.')
                    } else {
                        this.uploadChunks()
                    }
                } else {
                    this.$store.dispatch('notification/error', 'Silahkan isi salah satu kolom.')
                }
            },
            insertImageToEditor (imageUrl) {
                const range = this.editor.getSelection()
                let index = 0
                if (range) {
                    index  = range.index
                } else {
                    index  = this.editor.getLength() + 1
                }
                this.editor.insertEmbed(index, 'image', imageUrl)
            },
            setFileName (file) {
                // if (file !== null && file !== '' && (this.chunk.filename === null || this.chunk.filename === '')) {
                let filename = file.name.replace(/-/g, ' ')
                filename = filename.replace(/_/g, ' ')
                filename = filename.split('.').slice(0, -1).join('.')
                this.chunk.filename = filename.charAt(0).toUpperCase() + filename.slice(1)
                // }
            },
            resetlastChunkProcessed () {
                this.chunk.lastChunkProcessed = 0
            },
            resetfileStamp () {
                // console.log(this.chunk.fileStamp)
                this.chunk.fileStamp = this.chunk.filename.toLowerCase().replace(/[^\w ]+/g,'').replace(/ +/g,'-') + '-' + this.$moment().format('hh-mm-ss') + '-' + Math.floor(Math.random() * 101)
            },
            async uploadChunks () {
                this.isLoading.insertImage = true
                const sizePerChunk = 250000 // 250000 bytes = 250kb
                const chunks = Math.ceil(this.model.insertImage.upload.size / sizePerChunk)
                let i = this.chunk.lastChunkProcessed
                if (this.chunk.lastChunkProcessed === 0) {
                    this.resetfileStamp()
                }
                let response = null
                for (i; i < chunks; i++) {
                    const fileToUpload = this.model.insertImage.upload.slice(
                        i * sizePerChunk, Math.min(i * sizePerChunk + sizePerChunk, this.model.insertImage.upload.size), this.model.insertImage.upload.type
                    )
                    let formData = new FormData
                    const fileExtension = this.model.insertImage.upload.name.split('.').pop()
                    formData.set('file', fileToUpload, `${this.chunk.fileStamp}.${fileExtension}.part`)
                    formData.set('is_first', i === 0 ? 1 : 0)
                    formData.set('is_last', i === (chunks-1) ? 1 : 0)
                    formData.set('file_title', i === (chunks-1) ? this.chunk.filename : null)
                    const result = await this.$http({
                        method: 'POST',
                        data: formData,
                        url: process.env.VUE_APP_API_URL + 'file-assets',
                        headers: {
                            'Content-Type': 'application/octet-stream'
                        }
                    }).then(resp => {
                        console.log(resp)
                        response = resp
                        return true
                    }).catch(error => {
                        this.$store.dispatch('notification/error', error)
                        return false
                    })
                    console.log(result)
                    if (result === false) {
                        break;
                    } else {
                        this.chunk.lastChunkProcessed++
                    }
                }
                this.isLoading.insertImage = false
                if (i === chunks) {
                    if (response !== null) {
                        this.insertImageToEditor(response.data.file_url)
                        this.model.insertImage.upload = null
                        this.$bvModal.hide('modal-quill-insertimage')
                    } else {
                        this.$store.dispatch('notification/error', 'Media gagal diupload.')
                    }
                }
            }
        }
    }
</script>
<style src='@/../node_modules/quill/dist/quill.snow.css'></style> 