var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.model.id !== null)?_c('b-breadcrumb',{attrs:{"items":[{
      text: 'Daftar Akun Bank',
      href: '#/bank-accounts'
  }, {
      text: 'Detail Akun Bank',
      href: '#/bank-accounts/' + _vm.model.id
  }, {
      text: 'Ubah Akun Bank',
      active: true
}]}}):_c('b-breadcrumb',{attrs:{"items":[{
  text: 'Daftar Akun Bank',
  href: '/#/bank-accounts'
}, {
  text: 'Tambah Akun Bank',
  active: true
}]}}),_c('b-card',{staticStyle:{"max-width":"500px","margin":"auto"},attrs:{"title":(_vm.model.id !== null ? 'Edit' : 'Tambah') + ' Akun Bank'}},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('b-form-group',{attrs:{"id":"input-group-1","label":"Nama*:","label-for":"input-1"}},[_c('b-form-input',{attrs:{"id":"input-1","placeholder":"Ketikkan nama bank","required":""},model:{value:(_vm.model.bank_name),callback:function ($$v) {_vm.$set(_vm.model, "bank_name", $$v)},expression:"model.bank_name"}})],1),_c('b-form-group',{attrs:{"id":"input-group-2","label":"No. Rek*:","label-for":"input-2"}},[_c('b-form-input',{attrs:{"id":"input-2","placeholder":"Ketikkan no. rek","required":""},model:{value:(_vm.model.bank_account_number),callback:function ($$v) {_vm.$set(_vm.model, "bank_account_number", $$v)},expression:"model.bank_account_number"}})],1),_c('b-form-group',{attrs:{"id":"input-group-3","label":"Atas Nama*:","label-for":"input-3"}},[_c('b-form-input',{attrs:{"id":"input-3","placeholder":"Ketikkan atas nama","required":""},model:{value:(_vm.model.bank_account_person),callback:function ($$v) {_vm.$set(_vm.model, "bank_account_person", $$v)},expression:"model.bank_account_person"}})],1),_c('b-button',{attrs:{"type":"submit","variant":"primary","disabled":_vm.isLoading}},[(_vm.isLoading)?_c('font-awesome-icon',{attrs:{"icon":"spinner","spin":""}}):_vm._e(),_vm._v(" Simpan")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }