<template>
    <div>
        <b-card class="p-0 mt-4">
          <b-row class="mb-2">
            <b-col md="12">
              <h4 class="card-title">Sesi Live</h4>
            </b-col>
          </b-row>
          
          <b-row class="mb-2">
            <b-col md="12">
              <div v-if="facebook.checking">
                <font-awesome-icon icon="spinner" spin /> Checking Status Login FB
              </div>
              <div v-else>
                <template v-if="!facebook.loggedIn" >
                    <b-button id="login-with-facebook" variant="primary" style="background: #237aef; border-color: #237aef; color: #fff;" :disabled="facebook.loginLoading" class="mb-1" @click="loginFb()"><font-awesome-icon class="mr-1" v-if="facebook.loginLoading" icon="spinner" spin /> <font-awesome-icon v-else :icon="['fab', 'facebook']" class="mr-1" />Sambungkan Ke Facebook</b-button>
                </template>
                <template v-else>
                    <b-button id="create-new-video" @click.prevent="createNewOrderGroup" variant="secondary"  :disabled="facebook.liveLoading"><font-awesome-icon v-if="facebook.liveLoading" icon="spinner" spin /><font-awesome-icon v-else icon="plus" /> Sesi Live</b-button>
                    <p>
                      <small>Pastikan anda sudah live di facebook</small>
                    </p>
                </template>
              </div>
            </b-col>
          </b-row>

        <b-row class="mb-2">
          <b-col md="12" class="mb-1">
              <b-form inline @submit.prevent="doSearch">
                  <b-input placeholder="Judul" v-model="model.title" />
                  <b-form-select class="ml-2" v-model="model.is_live"
                  :options="options.order_group_live"></b-form-select>
                  <b-input-group class="ml-2">
                      <b-form-input
                      style="width: 120px; text-align: center;"
                      v-model="model.start_date"
                      type="text"
                      placeholder="Periode Awal"
                      autocomplete="off"
                      ></b-form-input>
                      <b-input-group-append>
                      <b-form-datepicker
                          v-model="model.start_date"
                          button-only
                          right
                          locale="en-US"
                      ></b-form-datepicker>
                      </b-input-group-append>
                  </b-input-group>
                  <b-input-group class="ml-2">
                      <b-form-input
                      style="width: 120px; text-align: center;"
                      v-model="model.end_date"
                      type="text"
                      placeholder="Periode Akhir"
                      autocomplete="off"
                      ></b-form-input>
                      <b-input-group-append>
                      <b-form-datepicker
                          v-model="model.end_date"
                          button-only
                          right
                          locale="en-US"
                      ></b-form-datepicker>
                      </b-input-group-append>
                  </b-input-group>
                  <b-button type="submit" class="ml-2" variant="secondary"><font-awesome-icon icon="search" /> Cari</b-button>
                  </b-form>
          </b-col>
        </b-row>
          <!-- <b-row class="mb-2">
            <b-col md="12">
              <b-form inline @submit.prevent="doSearch">
                <b-input placeholder="Cari..." v-model="model.name" />
                <b-button type="submit" class="ml-2" variant="secondary">Cari</b-button>
              </b-form>
            </b-col>
          </b-row> -->
          <paginated-table ref="dataTable" :can-delete="$store.getters['account/accountData'].role === 'admin'" :can-show="true" :can-edit="false" :can-approve="false" :can-reject="false" :data-url="apiUrl.orderGroup" :data-columns="fields" :data-params="['name', 'start_date', 'end_date', 'title', 'is_live']">
          </paginated-table>
        </b-card>
        <modal-live ref="modalLive" @onsubmit="startNewVideo"/>
    </div>
</template>
<script>
  import PaginatedTable from '@/components/PaginatedTable'
  import ModalLive from '@/pages/OrderGroup/ModalLive'

  export default {
    components: {
      PaginatedTable,
      ModalLive
    },
    data() {
      return {
        facebook: {
          user: null,
          checking: true,
          loggedIn: false,
          loginLoading: false,
          liveLoading: false
        },
        apiUrl: {
          orderGroup: process.env.VUE_APP_API_URL + 'order-groups'
        },
        model: {
          title: this.$route.query.title,
          is_live: this.$route.query.is_live,
          start_date: this.$route.query.start_date,
          end_date: this.$route.query.end_date
        },
        options: {
          order_group_live: [
            { value: null, text: 'Semua Status' },
            { value: 1, text: 'Live' },
            { value: 0, text: 'Selesai'},
          ]
        },
        items: [],
        fields: [
          {
            key: 'title',
            label: 'Judul Live'
          },
          {
            key: 'started_at',
            label: 'Jam Mulai'
          },
          {
            key: 'finished_at',
            label: 'Jam Selesai'
          },
          {
            key: 'live_status',
            label: 'Status'
          },
          { 
            key: 'actions', 
            label: '',
            thClass: 'actions',
            class: 'text-right'
          }
        ],
        account_configs: {
          fb_page: {
            id: '',
            name: ''
          }
        }
      }
    },
    mounted() {
        this.$store.dispatch('facebook/getLoginStatus').then(resp => {
          this.facebook.checking = false
          // this.hasLoggedIn = true
        }).catch(error => {
          this.facebook.checking = false
          // this.hasLoggedIn = false
        })
        this.getUrlParams()
    },
    // mounted() {
    //   this.getUrlParams()
    // },
    methods: {
      doSearch () {
        this.$refs.dataTable.doFilter(this.model)
      },
      getUrlParams () {
        this.model.title = this.model.title !== undefined ? this.model.title : null
        this.model.is_live = this.model.is_live !== undefined ? this.model.is_live : null
        this.model.start_date = this.model.start_date !== undefined ? this.model.start_date : null
        this.model.end_date = this.model.end_date !== undefined ? this.model.end_date : null
      },
      clearField () {
        this.model.name = null
      },
      loginFb () {
        this.facebook.loginLoading = true
        this.$store.dispatch('facebook/login').then(resp => {
          this.facebook.loginLoading = false
          this.facebook.loggedIn = true
          // window.emitter.$emit('user-fb-logged')
        }).catch(error => {
          this.facebook.loginLoading = false
          this.$store.dispatch('notification/error', error)
        })
      },
      createNewOrderGroup () {
        this.$refs.modalLive.open()
      },
      saveNewOrderGroup () {
        this.facebook.liveLoading = true
        const pageId =  localStorage.getItem('page_id');
        window.FB.api(pageId + '/live_videos', {
          fields: 'live_views'
        }, (response) => {
            if (!response || response.error) {
              this.$store.dispatch('notification/error', !response ? 'Gagal terhubung dengan video live.' : response.error)
              this.facebook.liveLoading = false
            } else {
              if (response.data.length > 0) {
                  let videoId = response.data[0].id
                  let videoDescription = ''
                  window.FB.api('/' + videoId, {
                    fields: 'id,title,description'
                  }, (response) => {
                      if (!response || response.error) {
                        this.$store.dispatch('notification/error', !response ? 'Gagal terhubung dengan video live.' : response.error)
                        this.facebook.liveLoading = false
                      } else {
                        if (typeof response.description === 'undefined') {
                          videoDescription = 'Live: ' + this.$moment().format('DD-MM-YYYY')
                        } else {
                          videoDescription = response.description
                        }
                        this.$http.post(`${process.env.VUE_APP_API_URL}order-groups`, {
                          source_id : videoId,
                          title : videoDescription
                        }).then(({data,status}) => {
                          // localStorage.setItem('fb_video_id',videoId)
                          // localStorage.setItem('fb_video_detail',JSON.stringify({id:videoId,title:videoDescription}))
                          this.facebook.liveLoading = false
                          this.$store.dispatch('notification/success','Sesi Live Berhasil Ditambahkan')
                          this.$router.push({
                            path: `/order-groups/${data.id}`
                          })
                        }).catch(error => {
                          this.$store.dispatch('notification/error', error)
                          this.facebook.liveLoading = false
                        })
                      }
                })
              } else {
                this.$store.dispatch('notification/error', 'Gagal terhubung dengan video live.')
                this.facebook.liveLoading = false
              }
            }
        })
      },
      getConfigs (force=false) {
        return new Promise((resolve, reject) => {
          return resolve()
          if (force === false && this.account_configs.fb_page.id !== '') {
            return resolve()
          } else {
            return this.$http.get(`${process.env.VUE_APP_API_URL}accounts/configs`).then(resp => {
              resp.data.forEach(d => {
                if (d.key === 'facebook_page_id') {
                  this.account_configs.fb_page.id = d.value
                } else if (d.key === 'facebook_page_name') {
                  this.account_configs.fb_page.name = d.value
                }
              })
              return resolve()
            }).catch(error => {
              return reject(error)
            })
          }
        })
      },
      startNewVideo() {
        this.$store.dispatch('facebook/getLoginStatus').then(resp => {
            const userId = resp.user_id
            this.facebook.liveLoading = true
            // generate long live user token
            return this.$store.dispatch('facebook/generateLongLiveUserToken', resp.access_token).then(resp => {
                window.FB.api('/' + userId + '/accounts', 'GET', {
                  access_token: resp.data.access_token
                }, (response) => {
                  if (!response || response.error) {
                    this.$store.dispatch('notification/error', !response ? 'Gagal memuat halaman' : response.error)
                  } else {
                    // this.getConfigs(false).then(() => {
                        this.facebook.liveLoading = false
                        const filtered = response.data.filter( (v) => {
                          return v.id === localStorage.getItem('page_id')
                        })
                        if (filtered.length > 0) {
                          // this.$store.dispatch('facebook/setPageId', localStorage.getItem('page_id'))
                          this.$store.dispatch('facebook/setPageToken', filtered[0].access_token)
                          this.saveNewOrderGroup()
                        } else {
                          this.$store.dispatch('notification/error', 'Halaman tidak ditemukan')
                        }
                    // }).catch(error => {
                    //   this.$store.dispatch('notification/error', 'Gagal memuat halaman, coba lagi')
                    //   this.facebook.liveLoading = false
                    // })
                  }
                })
            }).catch(error => {
                this.facebook.liveLoading = false
                this.$store.dispatch('notification/error',error)
            })
        }).catch(error => {
            this.facebook.liveLoading = false
            this.$store.dispatch('notification/error', error)
        })                
      }
    }
  }
</script>