<template>
  <div>
  <b-breadcrumb v-if="model.id !== null" :items="[{
        text: 'Daftar Company',
        href: '#/companies'
    }, {
        text: 'Detail Company',
        href: '#/companies/' + model.id
    }, {
        text: 'Ubah Company',
        active: true
  }]"></b-breadcrumb>
  <b-breadcrumb v-else :items="[{
    text: 'Daftar Company',
    href: '/#/companies'
  }, {
    text: 'Tambah Company',
    active: true
  }]"></b-breadcrumb>
  <b-card :title="(model.id !== null ? 'Edit' : 'Tambah') + (model.parent !== null ? ' Sub' : '')+ ' Company'" style="max-width: 500px;margin: auto;">
      <b-form @submit.prevent="onSubmit">
        <b-form-group id="input-group-1" label="Nama*:" label-for="input-1">
            <b-form-input
            id="input-1"
            v-model="model.name"
            placeholder="Ketikkan nama"
            required
            ></b-form-input>
        </b-form-group>
      <b-button type="submit" variant="primary" :disabled="isLoading"><font-awesome-icon v-if="isLoading"  icon="spinner" spin /> Simpan</b-button>
    </b-form>
  </b-card>
  </div>
</template>
<script>
    import QuillEditor from '@/components/QuillEditor'

    export default {
        components: {
          QuillEditor
        },
        data () {
            return {
                isLoading: false,
                apiUrl: {
                    companies: process.env.VUE_APP_API_URL + 'companies'
                },
                model: {
                    parent: null,
                    id: null,
                    name: null,
                    sort: null
                    // logo: null
                }
            }
        },
        watch: {
            '$route.params.id': function (val) {
                if (val !== undefined) {
                    this.getDetail()
                } else {
                    this.resetForm()
                }
            }
        },
        mounted () {
            if (typeof this.$route.query.sub_parent !== 'undefined') {
                this.model.parent = this.$route.query.sub_parent
            }
            if (this.$route.params.id !== undefined) {
                this.getDetail()
            }
        },
        methods: {
            resetForm () {
                this.model.id = null
                this.model.parent = null
                this.model.name = null
                this.model.sort = null
                this.model.description = null
            },
            onSubmit () {
                this.isLoading = true
                const formData = new FormData()
                if (this.model.id !== null && this.model.id !== '') {
                    formData.append('_method', 'PATCH');
                }
                if (this.model.parent !== null && this.model.parent !== '') {
                    formData.append('parent', this.model.parent);
                }
                if (this.model.name !== null && this.model.name !== '') {
                    formData.append('name', this.model.name);
                }
                if (this.model.sort !== null && this.model.sort !== '') {
                    formData.append('sort', this.model.sort);
                }
                // if (this.model.logo !== null && this.model.logo !== '') {
                //     formData.append('logo', this.model.logo);
                // }
                if (this.model.description !== null && this.model.description !== '') {
                    formData.append('description', this.model.description);
                }
                this.$http.post(this.apiUrl.companies + (this.model.id !== null && this.model.id !== '' ? '/' + this.model.id : ''), formData).then(response => {
                    this.isLoading = false
                    if (this.model.id !== null && this.model.id !== '') {
                        this.$store.dispatch('notification/success', 'Company berhasil diubah.')
                    } else {
                        this.$store.dispatch('notification/success', 'Company berhasil disimpan.')
                    }
                    this.$router.push('/companies/' + response.data.id)
                }).catch(error => {
                    this.isLoading = false
                    this.$store.dispatch('notification/error', error)
                })
            },
            getDetail () {
                this.isLoading = true
                this.$http.get(this.apiUrl.companies + '/' + this.$route.params.id)
                .then(response => {
                    this.model.id = response.data.id
                    this.model.parent = response.data.parent
                    this.model.name = response.data.name
                    this.model.sort = response.data.sort
                    this.model.description = response.data.description
                    this.isLoading = false
                }).catch(error => {
                    this.isLoading = false
                    this.$store.dispatch('notification/error', error)
                    if (typeof error !== 'undefined' && typeof error.response !== 'undefined' && typeof error.response.status !== 'undefined' && error.response.status === 404) {
                        this.$router.push('/notfound')
                    }
                })
            },
            onEditorChange (val) {
                this.model.description = val === '<p><br></p>' ? null : val
            }
        }
    }
</script>