var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.model.id !== null)?_c('b-breadcrumb',{attrs:{"items":[{
      text: 'Daftar Kategori Produk',
      href: '#/product-categories'
  }, {
      text: 'Detail Kategori Produk',
      href: '#/product-categories/' + _vm.model.id
  }, {
      text: 'Ubah Kategori Produk',
      active: true
}]}}):_c('b-breadcrumb',{attrs:{"items":[{
  text: 'Daftar Kategori Produk',
  href: '/#/product-categories'
}, {
  text: 'Tambah Kategori Produk',
  active: true
}]}}),_c('b-card',{staticStyle:{"max-width":"500px","margin":"auto"},attrs:{"title":(_vm.model.id !== null ? 'Edit' : 'Tambah') + ' Kategori Produk'}},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('b-form-group',{attrs:{"id":"input-group-1","label":"Nama*:","label-for":"input-1"}},[_c('b-form-input',{attrs:{"id":"input-1","placeholder":"Ketikkan nama","required":""},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}})],1),(_vm.model.id !== null)?_c('b-form-group',{attrs:{"id":"input-group-3","label":"Urutan*:","label-for":"input-3"}},[_c('b-form-input',{attrs:{"id":"input-3","placeholder":"Ketikkan urutan","required":""},model:{value:(_vm.model.sort),callback:function ($$v) {_vm.$set(_vm.model, "sort", $$v)},expression:"model.sort"}})],1):_vm._e(),_c('b-button',{attrs:{"type":"submit","variant":"primary","disabled":_vm.isLoading}},[(_vm.isLoading)?_c('font-awesome-icon',{attrs:{"icon":"spinner","spin":""}}):_vm._e(),_vm._v(" Simpan")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }